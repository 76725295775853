import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isFirstLaunch: true,
    isLogin: false,
    homeScreenState: '',
    currentUser: {},
    testId: '',
    locale: 'ko',
    calibrationMode: 'normal_calibration',
    calibrationPoint: 'five_point',
    passageId: '',
    question: [],
    deviceInformation: {
      screenResolution: {
        width: 1600,
        height: 2560,
      },
    },
    textMetadata: {
      font: 'Arial',
      fontSize: 40,
      lineHeight: 80,
      textBox: {
        x: 0,
        y: 0,
        width: 1600,
        height: Math.floor(2560 * 0.7),
      },
    },
    wordAoi: [],
    age: '',
    gender: '',
    grade: '',
    gradeType: '',
    testResultSelectedQuestion: {},
    isLoading: false,
  },
  getters: {},
  mutations: {
    changeIsLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    changeIsFirstLaunch(state, isFirstLaunch) {
      state.isFirstLaunch = isFirstLaunch;
    },
    changeTestId(state, value) {
      state.testId = value;
    },
    changePassageId(state, value) {
      state.passageId = value;
    },
    changeAge(state, age) {
      state.age = age;
    },
    changeLocale(state, locale) {
      state.locale = locale;
    },
    changeCalibrationMode(state, calibrationMode) {
      state.calibrationMode = calibrationMode;
    },
    changeCalibrationPoint(state, calibrationPoint) {
      state.calibrationPoint = calibrationPoint;
    },
    changeGender(state, gender) {
      state.gender = gender;
    },
    changeGrade(state, grade) {
      state.grade = grade;
    },
    changeGradeType(state, gradeType) {
      state.gradeType = gradeType;
    },
    changeTestResultSelectedQuestion(state, question) {
      state.testResultSelectedQuestion = question;
    },
    changeIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    changeQuestion(state, question) {
      state.question = question;
    },
  },
  actions: {},
  modules: {},
});
