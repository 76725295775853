<template>
<ScanPath/>
</template>

<script>
import ScanPath from '../components/result/ScanPath.vue';

export default {
  name: 'GoldenStandardView',
  components: {
    ScanPath,
  },
};
</script>

<style scoped>

</style>
