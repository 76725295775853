<template>
  <v-container class="d-flex flex-column align-center pt-15 pb-10" fill-height>
    <v-card class="d-flex flex-column pa-15" elevation="0" height="100%" width="100%">
      <div class="text-h3 bold mb-8">나이를 입력해 주세요</div>
      <v-container fill-height fluid>
        <v-row align="center"
               justify="center">
          <v-col>
            <v-text-field
              class="mb-15"
              v-model="age"
              label="나이"
              type="number"
              full-width
              required
              :rules="ageRules"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-spacer></v-spacer>
      <v-btn :disabled="isAgeEmpty" @click="directToGrade()" class="" width="" color="primary"
             height="5vh">다음
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'RegisterAgeView',
  mounted() {
    this.sampleMethod();
  },
  beforeDestroy() {
    this.sampleMethod();
  },
  data() {
    return {
      sample: 1,
      age: '',
      ageRules: [
        (v) => !!v || '나이를 입력해 주세요',
        (v) => (v && v.length <= 3) || '나이를 정확하게 입력해 주세요',
      ],
    };
  },
  methods: {
    sampleMethod() {
      this.sample += 1;
    },
    directToGrade() {
      this.$store.commit('changeAge', this.age);
      this.$router.push('registergrade');
    },
  },
  watch: {
    sample(newVal, oldVal) {
      console.log('sample ', oldVal, ' change to ', newVal);
    },
    age(newVal) {
      this.age = parseInt(newVal, 10).toString();
    },
  },
  computed: {
    sampleComputed() {
      return this.sample * 2;
    },
    isAgeEmpty() {
      return this.age === '';
    },
  },
};
</script>
