<template>
  <v-card class="d-flex flex-column pa-5 rounded-lg">
    <!-- <slot name="body"></slot> -->
    <!-- <template> -->
    <v-text-field
      v-model="name"
      single-line
      hide-details
      :label="$t('labelText.name')"
      class="mb-8"
    ></v-text-field>
    <v-text-field
      v-model="age"
      single-line
      hide-details
      :label="$t('labelText.age')"
      type="number"
      class="mb-8"
      max="13"
    ></v-text-field>
    <v-select
      v-model="gender"
      :items="genderItems"
      :label="$t('labelText.gender')"
      item-text="text"
      item-value="value"
      class="mb-4"
    ></v-select>
    <v-select
      v-model="type"
      :items="typeItems"
      label="코드"
      item-text="text"
      item-value="value"
      class="mb-4"
    ></v-select>
    <!-- </template> -->
    <div v-if="infoEmpty" class="red--text align-self-center">
      {{$t('messageText.warnEmptyInformation')}}
    </div>
    <v-row class="mt-4">
      <v-col cols="6">
        <v-btn @click="cancelDialog" block outlined color="red darken-4"
          >{{$t('buttonText.cancel')}}</v-btn
        >
      </v-col>
      <v-col cols="6">
        <v-btn @click="confirmDialog" block outlined color="teal darken-4"
          >{{$t('buttonText.confirm')}}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'TestStartInfoInputDialog',
  data() {
    return {
      name: '',
      age: '',
      gender: '',
      type: 'N',
      genderItems: [
        {
          text: this.$t('labelText.male'),
          value: 'male',
        },
        {
          text: this.$t('labelText.female'),
          value: 'female',
        },
      ],
      typeItems: [
        {
          text: 'N',
          value: 'N',
        },
        {
          text: 'A',
          value: 'A',
        },
        {
          text: 'D',
          value: 'D',
        },
        {
          text: 'L',
          value: 'L',
        },
        {
          text: 'C',
          value: 'C',
        },
        {
          text: 'T',
          value: 'T',
        },
        {
          text: 'I',
          value: 'I',
        },
        {
          text: 'E',
          value: 'E',
        },

      ],
      infoEmpty: false,
    };
  },
  methods: {
    cancelDialog() {
      this.$emit('cancelDialog');
    },
    confirmDialog() {
      if (this.checkInfoEmpty()) {
        console.log('confirmDialog');
        this.$emit('confirmDialog', {
          name: this.name, age: this.age, gender: this.gender, type: this.type,
        });
      } else {
        this.infoEmpty = true;
      }
    },
    checkInfoEmpty() {
      if (this.name !== '' && this.age !== '' && this.gender !== '') {
        return true;
      }
      return false;
    },
    resetValues() {
      this.name = '';
      this.age = '';
      this.gender = '';
      this.type = 'N';
    },
  },
  mounted() {
    this.resetValues();
  },
};
</script>
