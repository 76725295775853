import { render, staticRenderFns } from "./TextViewer.vue?vue&type=template&id=f7821ff6&scoped=true"
import script from "./TextViewer.vue?vue&type=script&lang=js"
export * from "./TextViewer.vue?vue&type=script&lang=js"
import style0 from "./TextViewer.vue?vue&type=style&index=0&id=f7821ff6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7821ff6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VIcon,VProgressCircular,VSlider})
