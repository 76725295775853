<template>
  <v-container class="d-flex flex-column" fluid fill-height>
    <v-card height="100%" width="100%" elevation="0">
      <MainLandingView v-if="isMainScreen"></MainLandingView>
      <TestStartView v-if="isTestStartScreen"></TestStartView>
      <TestListView v-if="isTestListScreen"></TestListView>
      <ScanPath v-if="isIntegrationResultScreen"></ScanPath>
      <SettingView v-if="isSettingScreen"></SettingView>
      <SentenceBasedTestVue v-if="false"></SentenceBasedTestVue>
      <SentenceTestSelectView v-if="isSentenceBasedScreen"></SentenceTestSelectView>
    </v-card>
    <!-- <v-spacer></v-spacer> -->
    <v-bottom-navigation v-model="value" color="primary" fixed>
<!--      <v-btn value="main">-->
<!--        Home-->
<!--        <v-icon>fas fa-lock</v-icon>-->
<!--      </v-btn>-->
      <v-btn value="test_start">
        {{$t('buttonText.testStart')}}
        <v-icon>fas fa-lock</v-icon>
      </v-btn>
      <v-btn value="test_list">
        {{$t('buttonText.testList')}}
        <v-icon>fas fa-lock</v-icon>
      </v-btn>
<!--      <v-btn value="integration_result">-->
<!--        {{$t('buttonText.integrationResult')}}-->
<!--        <v-icon>fas fa-lock</v-icon>-->
<!--      </v-btn>-->
      <v-btn value="setting">
        {{$t('buttonText.setting')}}
        <v-icon>fas fa-lock</v-icon>
      </v-btn>
<!--      <v-btn @click="exitApp">-->
<!--        {{$t('buttonText.exitApp')}}-->
<!--        <v-icon>mdi-exit-to-app</v-icon>-->
<!--      </v-btn>-->
<!--      <v-btn value="sentence_based">-->
<!--        {{$t('buttonText.sentenceBasedTest')}}-->
<!--      </v-btn>-->
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import MainLandingView from './MainLandingView.vue';
import TestListView from './TestListView.vue';
import TestStartView from './TestStartView.vue';
import SettingView from './SettingView.vue';
import SentenceBasedTestVue from './SentenceBasedTest.vue';
import SentenceTestSelectView from './SentenceTestSelectView.vue';
// import IntegrationResultView from './IntegrationResultView.vue';
import ScanPath from '../components/result/ScanPath.vue';

export default {
  name: 'MainView',
  mounted() {
    if (this.$route.query.list === 'true') {
      this.setListview();
    }
    console.log('this.$route.query', this.$route.query);
    this.sampleMethod();
    console.log('cookie', document.cookie);
  },
  beforeDestroy() {
    this.sampleMethod();
  },
  data() {
    return {
      sample: 1,
      value: 'main',
    };
  },
  methods: {
    sampleMethod() {
      this.sample += 1;
    },
    exitApp() {
      if (window.Unity) {
        window.Unity.call('exitApp');
      }
    },
    setListview() {
      this.$router.replace({ query: null });
      this.value = 'test_list';
    },
  },
  watch: {
    sample(newVal, oldVal) {
      console.log('sample ', oldVal, ' change to ', newVal);
    },
    value(newVal, oldVal) {
      console.log(`value newVal : ${newVal}, oldVal : ${oldVal}`);
    },
  },
  computed: {
    sampleComputed() {
      return this.sample * 2;
    },
    isMainScreen() {
      console.log(`isMainScreen  + ${this.value === 'main'}`);
      return this.value === 'main';
    },
    isTestStartScreen() {
      return this.value === 'test_start';
    },
    isTestListScreen() {
      return this.value === 'test_list';
    },
    isIntegrationResultScreen() {
      return this.value === 'integration_result';
    },
    isSettingScreen() {
      return this.value === 'setting';
    },
    isSentenceBasedScreen() {
      return this.value === 'sentence_based';
    },
  },
  components: {
    MainLandingView,
    TestStartView,
    TestListView,
    SettingView,
    // IntegrationResultView,
    ScanPath,
    SentenceBasedTestVue,
    SentenceTestSelectView,
  },
};
</script>
