<template>
  <div class="d-flex flex-column">
    <ToolbarVue></ToolbarVue>
    <v-container class="d-flex flex-column mt-8">
      <GradationVisualizationVue v-if="false"></GradationVisualizationVue>
      <FixationVisualizationVue></FixationVisualizationVue>
      <v-card class="mt-8" width="100%" height="70vh">시각화 페이지</v-card>
    </v-container>
  </div>
</template>

<script>
import ToolbarVue from '../components/Toolbar.vue';
import GradationVisualizationVue from '../components/GradationVisualization.vue';
import FixationVisualizationVue from '../components/FixationVisualization.vue';

export default {
  name: 'GazeVisualizationView',
  components: {
    ToolbarVue,
    GradationVisualizationVue,
    FixationVisualizationVue,
  },
};
</script>
