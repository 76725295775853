<template>
    <SentenceBasedTestVue :text="text" v-if="show">
    </SentenceBasedTestVue>

</template>

<script>
import axios from 'axios';
import SentenceBasedTestVue from './SentenceBasedTest.vue';

export default {
  name: 'SentenceReadingTextView',
  components: {
    SentenceBasedTestVue,
  },
  methods: {
    async getPageText() {
      console.log('getPageText', this.$store.state.passageId);
      const response = await axios(
        `https://stage-reading-api.seeso.io/api/passage/${this.$store.state.passageId}`,
      );
      this.text = response.data.passage.text.replaceAll(/&nbsp;/g, '');
    },
  },
  data() {
    return {
      text: '',
      show: false,
    };
  },
  async mounted() {
    console.log('SentenceReadingTextView');
    const tmpVue = this;
    window.onCalibrationFinished = () => {
      tmpVue.show = true;
    };
    if (window.Unity) {
      window.Unity.call('startCalibration');
    } else {
      tmpVue.show = true;
    }
    await this.getPageText();
  },
};
</script>
