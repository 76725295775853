<template>
  <v-overlay>
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
      style="position: fixed; bottom: 50%; top: 50%"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingView',
};
</script>
