import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
// import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import SplashView from '../views/SplashView.vue';
import TermView from '../views/TermView.vue';
import RegisterAgeView from '../views/RegisterAgeView.vue';
import RegisterGender from '../views/RegisterGender.vue';
import RegisterGrade from '../views/RegisterGrade.vue';
// import TestDetailResultView from '../views/TestDetailResultView.vue';
import GazeVisualizationView from '../views/GazeVisualizationView.vue';
import TestReadingTextView from '../views/TestReadingTextView.vue';
import TestQuestionView from '../views/TestQuestionView.vue';
import TestGuidanceView from '../views/TestGuidanceView.vue';
import GoldenStandardView from '../views/GoldenStandardView.vue';
import MainView from '../views/MainView.vue';
import SentenceBasedTest from '../views/SentenceBasedTest.vue';
import SentenceReadingTextView from '../views/SentenceReadingTextView.vue';
// import NewCalibrationView from '../views/NewCalibrationView.vue';

// eslint-disable-next-line no-unused-vars
import store from '../store/index';
// import TestListView from '../views/TestListView.vue';
// import TestStartView from '../views/TestStartView.vue';
// import SettingView from '../views/SettingView.vue';

import { hostUrl } from '../utils/ConnectionUtils';

const TestDetailResultView = () => import('../views/TestDetailResultView.vue');
const NewTestDetailResultView = () => import('../views/NewTestDetailResultView.vue');

Vue.use(VueRouter);

// eslint-disable-next-line no-unused-vars
async function requireAuth(to, from, next) {
  try {
    console.log('requireAuth', document.cookie);
    const response = await axios.get(`${hostUrl}/api/users/authorized`, {
      withCredentials: true,
    });

    if (response.status === 200) {
      const result = response.data;

      if (result.user) {
        store.state.currentUser = result.user;
      }

      console.log(response.status, response);
      next();
    } else {
      console.log(response.status, ' 2');

      next('/login');
    }
  } catch (e) {
    console.log(e);

    next('/login');
  }
}
const routes = [
  // {
  //   path: '/dot',
  //   name: 'NewCalibrationView',
  //   component: NewCalibrationView,
  //   // beforeEnter: requireNewCalibrationViewAuth,
  // },
  {
    path: '/',
    name: 'home',
    component: MainView,
    beforeEnter: requireAuth,
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: RegisterView,
  },
  {
    path: '/splash',
    name: 'SplashView',
    component: SplashView,
  },
  {
    path: '/term',
    name: 'TermView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TermView,
  },
  {
    path: '/registerage',
    name: 'registerAgeView',
    component: RegisterAgeView,
  },
  {
    path: '/registergender',
    name: 'registerGender',
    component: RegisterGender,
  },
  {
    path: '/registergrade',
    name: 'registerGrade',
    component: RegisterGrade,
  },
  {
    path: '/main',
    name: 'main',
    component: MainView,
    beforeEnter: requireAuth,
  },
  {
    path: '/testdetailresult',
    name: 'testDetailResult',
    component: TestDetailResultView,
    beforeEnter: requireAuth,
  },
  {
    path: '/newtestdetailresult',
    name: 'newtestDetailResult',
    component: NewTestDetailResultView,
  },
  {
    path: '/gazevisualization',
    name: 'gazeVisualization',
    component: GazeVisualizationView,
  },
  {
    path: '/testReadingTextView',
    name: 'testReadingTextView',
    component: TestReadingTextView,
  },
  {
    path: '/testQuestionView',
    name: 'testQuestionView',
    component: TestQuestionView,
  },
  {
    path: '/testGuidanceView',
    name: 'testGuidanceView',
    component: TestGuidanceView,
  },
  {
    path: '/golden',
    name: 'goldenStandardView',
    component: GoldenStandardView,
  },
  {
    path: '/sentence',
    name: 'SentenceBasedTest',
    component: SentenceBasedTest,
  },
  {
    path: '/sentencetest',
    name: 'SentenceReadingTextView',
    component: SentenceReadingTextView,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
  // store.commit('changeIsLoading', true);
  next();
});
// eslint-disable-next-line no-unused-vars
router.afterEach(async (to, from) => {
  // store.commit('changeIsLoading', false);
});

export default router;
