<template>
<v-card>
        <v-card-title class="text-h5">
          문장 읽기가 끝났습니다
        </v-card-title>
        <v-card-text>문제풀이로 넘어가시겠습니까?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogButton(false)"
          >
            취소
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialogButton(true)"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
export default {
  name: 'SentenceTestEndDialog',
  methods: {
    dialogButton(value) {
      this.$emit('close:dialog', value);
    },
  },
};
</script>
