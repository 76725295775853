<template>
  <div class="d-flex align-center justify-center flex-column" fill-height>
    <v-card elevation="0" class="p-2" height="100%" width="100vw">
      <v-carousel
        v-model="model"
        height="70vh"
        :show-arrows="false"
        :continuous="false"
        hide-delimiters
        touchless
      >
        <v-carousel-item v-for="(color) in colors" :key="color">
          <v-sheet :color="color" height="100%" tile>
            <!-- <v-row class="fill-height" align="center" justify="center">
              <div class="text-h2">Slide {{ i + 1 }}</div>
            </v-row> -->
<!--            <v-img-->
<!--              src="https://thumbs.dreamstime.com/z/man-reading-book-studying-literature-flat-design-modern-style-girl-illustration-isolated-148343578.jpg"-->
<!--              max-height="300"-->
<!--            >-->
<!--              <div class="text-h2">Slide {{ i + 1 }}</div>-->
<!--            </v-img>-->
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-card class="d-flex flex-column" height="30vh">
        <!-- <v-card-title> {{ title }} </v-card-title> -->
        <div class="text-h3 mt-12 ml-5 mr-5" v-text="title"></div>
        <div class="text-h5 mt-10 ml-5 mr-5" v-text="content"></div>

        <div class="d-flex pa-5 mt-auto">
          <v-btn class="text-h5" @click="showNext()" v-if="showNextButton" text color="primary"
            >NEXT</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="text-h5" @click="directToLogin()" text color="primary"> SKIP </v-btn>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
const arrSplashTitle = [
  '1번째 Splash Title 입니다',
  '2번째 Splash Title 입니다',
  '3번째 Splash Title 입니다',
  '4번째 Splash Title 입니다',
  '5번째 Splash Title 입니다',
];
const arrSplashContent = [
  '본인이 책을 얼마나 잘 읽고 있는지 알고 있나요?',
  '2번째 설명 문구입니다.',
  '3번째 설명 문구입니다.',
  '4번째 설명 문구입니다.',
  '5번째 설명 문구입니다.',
];
export default {
  name: 'SplashView',
  beforeMount() {
    this.initialize();
  },
  mounted() {
    this.sampleMethod();
  },
  beforeDestroy() {
    console.log('beforeDestroy');
    this.store.state.isFirstLaunch = false;
  },
  data() {
    return {
      sample: 1,
      router: null,
      store: null,
      isLogin: false,
      model: 0,
      colors: ['primary', 'secondary', 'yellow darken-2', 'red', 'orange'],
    };
  },
  methods: {
    initialize() {
      this.router = this.$router;
      this.store = this.$store;
    },
    sampleMethod() {
      this.sample += 1;
    },
    // Splash Finished
    directToLogin() {
      this.router.push('/login');
    },
    setLogin(value) {
      this.$store.commit('SET_LOGIN', value);
    },
    checkLogin() {
      console.log('login before');
      if (this.$store.getters.loginValue === true || this.$store.getters.loginValue === 'true') {
        console.log('login already');
        this.$router.push('login');
      }
    },
    showNext() {
      this.model += 1;
      console.log(this.model);
    },
  },
  watch: {
    sample(newVal, oldVal) {
      console.log('sample ', oldVal, ' change to ', newVal, ' computed ', this.sampleComputed);
    },
  },
  computed: {
    sampleComputed() {
      return this.sample * 2;
    },
    title() {
      return arrSplashTitle[this.model];
    },
    content() {
      return arrSplashContent[this.model];
    },
    showNextButton() {
      return !(this.model >= 4);
    },
  },
};
</script>

<style scoped></style>
