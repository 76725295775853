var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('div',{staticStyle:{"background-color":"white","z-index":"1"},attrs:{"id":"container"}}),_c('v-layout',{staticClass:"d-flex flex-column",attrs:{"fluid":"","column":"","fill-height":""}},[_c('v-flex',{attrs:{"xs8":"","id":"textTemplate","fluid":""}},[_c('v-card',{staticClass:"pa-3",attrs:{"width":"100%","height":"100%","elevation":"0"}},[_c('div',{staticStyle:{"line-height":"300%","word-break":"keep-all"},attrs:{"id":"readingText"}})])],1),_c('v-flex',{staticClass:"d-flex flex-column mt-5",attrs:{"xs4":""}},[_c('v-spacer'),(_vm.$i18n.locale !== 'en')?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.textIndex !== -1),expression:"textIndex !== -1"}],staticClass:"align-self-end",attrs:{"width":"40%","x-large":"","outlined":""},on:{"click":function () {
            _vm.stopSignal(("passageText" + (_vm.textIndex + 1)));
            _vm.sendReadingData();
            _vm.directToQuestion();
          }}},[_vm._v("다 읽었어요")]):_vm._e(),(_vm.$i18n.locale === 'en')?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.textIndex !== -1),expression:"textIndex !== -1"}],staticClass:"align-self-end",attrs:{"width":"40%","large":"","outlined":""},on:{"click":function () {
            _vm.stopSignal(("passageText" + (_vm.textIndex + 1)));
            _vm.sendReadingData();
            _vm.directToQuestion();
          }}},[_vm._v("Finished")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }