<template>
  <div id='container' class="pt-5 pb-5" >
<div id="konva_container" style="position:absolute; z-index: 9999;"></div>
<div>

    <v-card width="80vw" id="card1" >
    </v-card>

    <v-card width="50vw"><p id="card2"></p></v-card>
    </div>
  </div>
</template>
<script>
import Konva from 'konva';

const tmpFixations = [
  {
    word: 'AaasdfsdasdaaaaaaBCD',
    line: 1,
  },
  {
    word: 'DEFGH',
    line: 1,
  },
  {
    word: 'IJK',
    line: 1,
  },
  {
    word: 'LMNO',
    line: 1,
  },
  {
    word: 'PQRS',
    line: 1,
  },
  {
    word: 'TUVWXYZ',
    line: 1,
  },
  {
    word: '2AaaaㄴㄴㄴaaaaBCD',
    line: 2,
  },
  {
    word: '2DEFGH',
    line: 2,
  },
  {
    word: '2IJK',
    line: 2,
  },
  {
    word: '2LMNO',
    line: 2,
  },
  {
    word: '2PQRS',
    line: 2,
  },
  {
    word: '2TUVWXYZ',
    line: 2,
  },
];

const arrFixations = [
  {
    line: 1,
    word: 1,
    duration: 4,
  },
  {
    line: 1,
    word: 3,
    duration: 7,
  },
];

let konvaStage = null;

function calFontSize(value) {
  return (value * 4) / 3;
}

function getDrawDataLayout() {
  const layer = new Konva.Layer();
  for (let i = 0; i < arrFixations.length; i += 1) {
    const circleX = document.querySelector(`#bodyWord${arrFixations[i].line}-${arrFixations[i].word}`)
      .offsetLeft
      + document.querySelector(`#bodyWord${arrFixations[i].line}-${arrFixations[i].word}`)
        .offsetWidth
      / 2;
    const circleY = document.querySelector(`#bodyWord${arrFixations[i].line}-${arrFixations[i].word}`)
      .offsetTop
      + document.querySelector(`#bodyWord${arrFixations[i].line}-${arrFixations[i].word}`)
        .offsetHeight
      / 2;
    const circle = new Konva.Circle({
      x: circleX,
      y: circleY,
      fill: 'rgba(255,0,0,0.3)',
      stroke: 'rgba(255,0,0,1)',
      strokeWidth: 2,
      radius: arrFixations[i].duration * 2,
    });
    layer.add(circle);
  }
  layer.zIndex(9999);
  return layer;
  /*
  console.log(
          `offSet : ${
            document.querySelector(`#bodyWord${arrFixations[i].line}-${arrFixations[i].word}`)
              .offsetLeft
          }`,
        );
  */
}
function setKonvaState() {
  const width = document.querySelector('#container').clientWidth; // document.querySelector('#card1').offsetWidth
  const height = document.querySelector('#container').offsetHeight; // document.querySelector('#card1').offsetHeight
  if (konvaStage === null || konvaStage === undefined) {
    konvaStage = new Konva.Stage({
      container: 'konva_container',
      width,
      height,
    });
  }
  konvaStage.clear();
  konvaStage.add(getDrawDataLayout());
}

export default {
  name: 'FixationVisualization',
  mounted() {
    this.getMaxLen();

    // this.setText();
  },
  methods: {
    getMaxLen() {
      let line = 0;
      let text = '';
      let maxText = '';
      let maxLine = 0;
      for (let i = 0; i < tmpFixations.length; i += 1) {
        text += tmpFixations[i].word;
        text += ' ';
        console.log(`addedText : ${text}`);
        if (tmpFixations[i].line !== line || i === tmpFixations.length - 1) {
          line = tmpFixations[i].line;
          console.log(`maxText : ${maxText} maxLine : ${maxLine} text : ${text}`);
          if (maxText.length < text.length) {
            maxText = text;
            maxLine = line;
          }

          text = '';
        }
      }
      //   console.log(`maxLine : ${maxLine} maxText : ${maxText}`);
      for (let j = 100; j >= 1; j -= 1) {
        document.querySelector('#card1').innerHTML = maxText;
        document.querySelector('#card1').style.fontSize = `${j}px`;
        if (document.querySelector('#card1').clientHeight - 4 > calFontSize(j)) {
          // -4 for padding
        } else {
          break;
        }
      }

      for (let k = 100; k >= 1; k -= 1) {
        document.querySelector('#card2').innerHTML = maxText;
        document.querySelector('#card2').style.fontSize = `${k}px`;
        if (document.querySelector('#card2').clientHeight - 4 > calFontSize(k)) {
          // -4 for padding
        } else {
          break;
        }
      }
      this.setText();
    },
    setText() {
      // document.querySelector('#card1').innerHTML
      let line = 0;
      let text = '';
      let bodyText = '';
      for (let i = 0; i < tmpFixations.length; i += 1) {
        if (line !== tmpFixations[i].line || i === tmpFixations.length - 1) {
          if (text !== '') {
            bodyText += `${text}<br/>`;
            text = '';
          }
        }
        line = tmpFixations[i].line;
        text += `<span id='bodyWord${line}-${i + 1}'>${tmpFixations[i].word} </span>`;
      }
      document.querySelector('#card1').innerHTML = bodyText;
      // this.setFixationVisual();
      setKonvaState();
    },
    setFixationVisual() {
      for (let i = 0; i < arrFixations.length; i += 1) {
        // eslint-disable-next-line no-unused-expressions
        console.log(
          `offSet : ${
            document.querySelector(`#bodyWord${arrFixations[i].line}-${arrFixations[i].word}`)
              .offsetLeft
          }`,
        );
      }
    },
  },
};
</script>
