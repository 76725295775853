<template>
  <div class="d-flex flex-column" width="100%" height="100%">
    <v-dialog
    v-model="dialog"
    width="500"
    height="500"
    >
      <v-card>
        <v-card-title class="text-h5">
        </v-card-title>
        <v-text-field
          label="현재 비밀번호"
          v-model="currentPassword"
          clearable
          class="pl-10 pr-10"
          type="password"
        ></v-text-field>
        <v-text-field
          v-model="newPassword"
          label="변경 비밀번호"
          clearable
          class="pl-10 pr-10"
          type="password"
        ></v-text-field>
        <v-text-field
          v-model="newPasswordConfirm"
          label="변경 비밀번호 확인"
          clearable
          class="pl-10 pr-10"
          type="password"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            취소
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="changePassword"
            :disabled="
            currentPassword === '' ||
            newPasswordConfirm === '' ||
            newPassword === '' ||
            newPasswordConfirm !== newPassword
"
          >
            변경
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-row> -->
    <!-- <div class="pt-3 pb-3">개인정보 수정</div> -->
    <!-- </v-row> -->
    <!-- <v-divider></v-divider> -->
    <!-- <v-row> -->
    <!-- <div class="pt-3 pb-3">로그아웃</div> -->
    <!-- </v-row> -->
    <div class="d-flex flex-row">
      <v-switch v-model="localEN" label="English"> </v-switch>
    </div>
<!--    <div class="d-flex flex-row">-->
<!--      <v-radio-group v-model="calibrationMode" row>-->
<!--        <v-radio label="일반 캘리브레이션" value="normal_calibration"></v-radio>-->
<!--        <v-radio label="쉬운 캘리브레이션" value="easy_calibration"></v-radio>-->
<!--        <v-radio label="캘리브레이션 생략" value="no_calibration"></v-radio>-->
<!--      </v-radio-group>-->
<!--    </div>-->
<!--    <div class="d-flex flex-row pb-3">-->
<!--      <v-radio-group v-model="calibrationPoint" row>-->
<!--        <v-radio label="5점 캘리브레이션" value="five_point"></v-radio>-->
<!--        <v-radio label="6점 캘리브레이션" value="six_point"></v-radio>-->
<!--      </v-radio-group>-->
<!--    </div>-->
    <v-container>
      <v-row>
        <v-btn outlined class="mr-3" @click="dialog = true" style="margin-bottom: 12px;">
          {{$t('buttonText.changePassword')}}
        </v-btn>
      </v-row>
      <v-row>
        <v-btn outlined class="mr-3" @click="logout"  style="margin-bottom: 12px;">
          {{$t('buttonText.logout')}}
          <v-icon class="pl-2"> mdi-key-remove </v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-btn outlined @click="exitApp"  style="margin-bottom: 12px;">
          {{$t('buttonText.exit')}}
          <v-icon class="pl-2"> mdi-exit-to-app </v-icon>
        </v-btn>
      </v-row>
    </v-container>

    </div>
</template>

<script>
import axios from 'axios';
import { hostUrl } from '../utils/ConnectionUtils';

export default {
  name: 'SettingView',
  mounted() {
    this.sampleMethod();
    this.localEN = this.$store.state.locale === 'en';
    if (this.$store.state.calibrationMode) {
      this.calibrationMode = `${this.$store.state.calibrationMode}`;
    }
    if (this.$store.state.calibrationPoint) {
      this.calibrationPoint = `${this.$store.state.calibrationPoint}`;
    }
  },
  beforeDestroy() {
    this.sampleMethod();
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      sample: 1,
      localEN: false,
      calibrationMode: 'normal_calibration',
      calibrationPoint: 'five_point',
      dialog: false,
    };
  },
  methods: {
    async changePassword() {
      const postBody = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      };
      const response = await axios.post(`${hostUrl}/api/users/password`, postBody);

      this.currentPassword = '';
      this.newPassword = '';
      this.newPasswordConfirm = '';
      this.dialog = false;
      if (response.data === true) {
        alert('비밀번호 변경 성공');
      } else {
        alert('비밀번호 변경 실패');
      }
    },
    sampleMethod() {
      this.sample += 1;
    },
    exitApp() {
      if (window.Unity) {
        window.Unity.call('exitApp');
      }
    },
    async logout() {
      await axios.get(`${hostUrl}/api/auth/logout`);
      if (window.Unity) {
        window.Unity.call('logout');
      }
      this.$router.push('/login');
    },
  },
  watch: {
    sample(newVal, oldVal) {
      console.log('sample ', oldVal, ' change to ', newVal);
    },
    localEN(newVal) {
      if (newVal) {
        this.$i18n.locale = 'en';
        if (window.Unity) {
          window.Unity.call('SetLanguage_en');
        }
      } else {
        this.$i18n.locale = 'ko';
        if (window.Unity) {
          window.Unity.call('SetLanguage_ko');
        }
      }
    },
    calibrationMode(newVal) {
      this.$store.commit('changeCalibrationMode', newVal);
      let mode = '';
      if (newVal === 'normal_calibration') {
        mode = 'normal';
      } else if (newVal === 'easy_calibration') {
        mode = 'easy';
      } else if (newVal === 'no_calibration') {
        mode = 'no';
      }
      if (window.Unity) {
        window.Unity.call(`SetCalibrationMode_${mode}`);
      }
    },
    calibrationPoint(newVal) {
      this.$store.commit('changeCalibrationPoint', newVal);
      let point = '';
      if (newVal === 'five_point') {
        point = 'five';
      } else if (newVal === 'six_point') {
        point = 'six';
      }
      if (window.Unity) {
        window.Unity.call(`SetCalibrationPoint_${point}`);
      }
    },
  },
  computed: {
    sampleComputed() {
      return this.sample * 2;
    },
  },
};
</script>
