<template>
  <v-container class="d-flex flex-column pa-10" fluid fill-height>
    <v-card class="pa-3 mb-10" width="100%" height="40%">통합 검사 결과 요약</v-card>
    <v-card class="pa-3 mb-5" width="100%" height="10%">검사 시작하기</v-card>
    <v-card class="pa-3 mb-5" width="100%" height="10%">검사 기록 보러가기</v-card>
    <v-card class="pa-3 mb-5" width="100%" height="10%">트레이닝 시작하기</v-card>
  </v-container>
</template>

<script>
export default {
  name: 'MainLandingView',
  mounted() {
    this.sampleMethod();
  },
  beforeDestroy() {
    this.sampleMethod();
  },
  data() {
    return {
      sample: 1,
    };
  },
  methods: {
    sampleMethod() {
      this.sample += 1;
    },
  },
  watch: {
    sample(newVal, oldVal) {
      console.log('sample ', oldVal, ' change to ', newVal);
    },
  },
  computed: {
    sampleComputed() {
      return this.sample * 2;
    },
  },
};
</script>
