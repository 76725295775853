<template>
  <v-app-bar
  fixed
  dense
  >
    <v-btn icon class="" @click="goBack()">
      <v-icon> mdi-arrow-left </v-icon>
    </v-btn>

    <v-toolbar-title></v-toolbar-title>

    <v-spacer></v-spacer>
    <v-progress-circular
      class="mr-5"
      v-if="showPrint && isPrinting"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <v-icon v-if="showPrint && !isPrinting" @click="print" class="mr-5">
      mdi-printer-outline
    </v-icon>
    <v-icon @click="exitApp" class="ml-5"> mdi-exit-to-app </v-icon>

  </v-app-bar>
  <!-- <div class="pa-3">
    <v-btn @click="goBack()" absolute class="pa-0">
      <v-icon> mdi-arrow-left </v-icon>
    </v-btn>
    <div class="d-flex align-center justify-center">
      <div class="align-center" width="100%">Toolbar Title</div>
    </div>
  </div> -->
</template>
<script>
export default {
  name: 'ToolbarVue',
  methods: {
    exitApp() {
      if (window.Unity) {
        window.Unity.call('exitApp');
      }
    },
    goBack() {
      this.$router.push('main?list=true');
    },
    print() {
      // window.print();
      this.$emit('print');
    },
  },
  props: {
    showPrint: {
      type: Boolean,
      default: false,
    },
    isPrinting: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
