<template>
<v-container class="d-flex align-center justify-center"  fill-height fluid>
      <v-row
        fill-height
        fluid
        style="height: 100%;"
        align="center"
        justify="center"
      >
    <div style="height:50%; width: 90%; justify-content: center; align-items: center; display: flex ;" v-touch="{
        up: () => swipe('up'),
        down: () => swipe('down'),
        left: () => swipe('left'),
        right:()=>swipe('right')
      }" >
      <div v-for="(sentenceText,i) in arrText" :key="i" :id='"sentenceText"+i' v-show='activeSlide === i'  >
        {{sentenceText.sentence}}
      </div>
    </div>
    <div v-if="!sentenceCalculateLoading" id="fooSentence" style="word-break: keep-all;height:50%; width: 90%;" ></div>
    </v-row>
    <v-dialog max-width="300" persistent v-model="showEndDialog">

    <SentenceTestEndDialog @close:dialog='dialogEnd'></SentenceTestEndDialog>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import SentenceTestEndDialog from '../components/SentenceTestEndDialog.vue';
import { getTextByPassage } from '../utils/PassageTextUtils';

export default {
  name: 'SentenceBasedTest',
  mounted() {
    // document.body.requestFullscreen().catch((e) => { this.error = e; });
    // window.screen.orientation.lock('landscape');
    // this.rotateScreenLandscape();
    this.startRecording();
    // this.splitSentences();
    this.splitByWordBreak();
    this.setWordArrByLine();
    this.sentenceCalculateLoading = true;
  },
  beforeDestroy() {
    // document.exitFullscreen();
    // window.screen.orientation.unlock();
    // this.rotateScreenPortrait();
    this.stopRecording();
  },

  methods: {
    async getPageText() {
      const response = await axios(
        'https://stage-reading-api.seeso.io/api/passage/62c267e56dbe4b3c8df42c35',
      );
      return response.data.passage.text;
    },
    startRecording() {
      if (window.Unity) {
        window.Unity.call('startRecording');
      }
    },
    stopRecording() {
      if (window.Unity) {
        window.Unity.call('stopRecording');
      }
    },
    rotateScreenLandscape() {
      if (window.Unity) {
        window.Unity.call('landscapeScreen');
      }
    },
    rotateScreenPortrait() {
      if (window.Unity) {
        window.Unity.call('portraitScreen');
      }
    },
    swipeBefore() {
      const nextSlide = this.activeSlide - 1;
      if (nextSlide >= 0) {
        document.querySelector(`#sentenceText${this.activeSlide}`).animate([{ opacity: 1, transform: 'translateY(0px)' }, { opacity: 0, transform: 'translateY(60px)' }], { duration: this.speed });
        if (this.textIndex > 0) this.textIndex -= 1;
        else this.textIndex = 0;
        setTimeout(() => {
          this.activeSlide = nextSlide;
          if (this.textIndex < this.arrText.length - 1) this.textIndex += 1;
          else this.textIndex = this.arrText.length - 1;
        }, this.speed);
      }
    },
    swipeNext() {
      const nextSlide = this.activeSlide + 1;
      if (nextSlide <= this.arrText.length - 1) {
        document.querySelector(`#sentenceText${this.activeSlide}`).animate([{ opacity: 1, transform: 'translateY(0px)' }, { opacity: 0, transform: 'translateY(-60px)' }], { duration: this.speed });
        setTimeout(() => {
          this.activeSlide = nextSlide;
          if (this.textIndex < this.arrText.length - 1) this.textIndex += 1;
          else this.textIndex = this.arrText.length - 1;
        }, this.speed);
      } else {
        this.showEndDialog = true;
      }
    },
    swipe(direction) {
      switch (direction) {
        case 'left':
        case 'up':
          this.swipeNext();
          break;
        case 'right':
        case 'down':
          this.swipeBefore();
          break;
        default: { console.log('error'); }
      }
    },
    dialogEnd(value) {
      if (value) {
        this.directToQuestion();
      }
      this.showEndDialog = false;
    },
    splitByWordBreak() {
      // const foo = document.querySelector('#fooSentence').innerHTML;
      // console.log('splitByWordBreak', foo, foo.match(/\n/g));
      // const fooArr = this.text.split(' ');
      // let fooHtml = '';
      // for (let i = 0; i < fooArr.length; i += 1) {
      //   fooHtml += `<span id='sentence_word_${i}'>${fooArr[i]} </span>`;
      // }
      document.querySelector('#fooSentence').innerHTML = getTextByPassage('sentence_word_', this.text);
    },
    setWordArrByLine() {
      const fooArr = this.text.split(' ');
      let lastTop = 0;
      let lineNum = 0;
      let lineStr = '';
      const arrText = [];
      console.log('setWordArrByLine', fooArr[fooArr.length - 1]);
      for (let i = 0; i < fooArr.length; i += 1) {
        if (lastTop !== document.querySelector(`#sentence_word_${i}`).offsetTop) {
          lastTop = document.querySelector(`#sentence_word_${i}`).offsetTop;
          lineNum += 1;
          if (lineStr !== '') {
            arrText.push({
              sentence: lineStr,
              line: lineNum,
            });
            lineStr = '';
          }
        }
        lineStr += `${fooArr[i]} `;
        if (i === fooArr.length - 1) {
          lineNum += 1;
          if (lineStr !== '') {
            arrText.push({
              sentence: lineStr,
              line: lineNum,
            });
          }
        }
      }
      this.arrText = arrText;
    },
    splitSentences() {
      let passageText = this.text;
      if (passageText.includes('“')) {
        passageText = passageText.replaceAll('“', '"');
      }
      if (passageText.includes('”')) {
        passageText = passageText.replaceAll('”', '"');
      }
      if (passageText.includes('.')) {
        if (passageText.includes('"')) {
          const sentenceSplitArr = passageText.split('"');
          let strPosition = 1;
          const fooArr = [];
          for (let i = 0; i < sentenceSplitArr.length; i += 1) {
            if (i === strPosition) {
              strPosition += 2;
              fooArr.push(sentenceSplitArr[i]);
            } else if (sentenceSplitArr[i].includes('.') && sentenceSplitArr[i] !== '') {
              const splitedSentence = sentenceSplitArr[i].split('.');
              for (let j = 0; j < splitedSentence.length; j += 1) {
                if (splitedSentence[j] !== '') { fooArr.push(splitedSentence[j]); }
              }
            } else if (sentenceSplitArr[i].includes(',') && sentenceSplitArr[i] !== '') {
              const splitedSentence = sentenceSplitArr[i].split(',');
              for (let j = 0; j < splitedSentence.length; j += 1) {
                if (splitedSentence[j] !== '') { fooArr.push(splitedSentence[j]); }
              }
            }
          }
          this.arrText = fooArr;
        } else {
          const fooArrText = this.text.split('.');
          let resultArr = [];
          for (let i = 0; i < fooArrText.length; i += 1) {
            if (fooArrText[i].includes(',')) {
              resultArr = resultArr.concat(fooArrText[i].split(','));
            } else {
              resultArr.push(fooArrText[i]);
            }
          }
          this.arrText = resultArr;
        }
      } else {
        this.arrText = [this.text];
      }
    },
    directToQuestion() {
      if (window.Unity) {
        window.Unity.call('stopRecording');
      }
      this.$router.push('testQuestionView');
    },
  },
  data() {
    return {
      arrText: [],
      textIndex: 0,
      activeSlide: 0,
      speed: 300,
      showEndDialog: false,
      sentenceCalculateLoading: false,
    };
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  components: {
    SentenceTestEndDialog,
  },
};
</script>
