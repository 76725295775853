import { render, staticRenderFns } from "./TestReadingTextView.vue?vue&type=template&id=4909ad83&scoped=true"
import script from "./TestReadingTextView.vue?vue&type=script&lang=js"
export * from "./TestReadingTextView.vue?vue&type=script&lang=js"
import style0 from "./TestReadingTextView.vue?vue&type=style&index=0&id=4909ad83&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4909ad83",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VContainer,VFlex,VLayout,VSpacer})
