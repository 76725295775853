<template>
  <v-app id="vapp">
    <!-- <v-app-bar
      app
      color="primary"
      dark
      inverted-scroll
    >
    </v-app-bar> -->
    <v-main>
      <router-view />
      <LoadingView v-show="isLoading" />
    </v-main>
  </v-app>
</template>

<script>
import LoadingView from './views/LoadingView.vue';

let unsubscribe = null;

export default {
  name: 'App',

  data: () => ({
    //
    isLoading: false,
  }),
  components: {
    LoadingView,
  },
  mounted() {
    this.subscribeLoading();
  },
  methods: {
    subscribeLoading() {
      unsubscribe = this.$store.subscribe((action, state) => {
        this.isLoading = state.isLoading;
      });
    },

  },
  beforeDestroy() {
    if (unsubscribe) unsubscribe();
  },
};
</script>

<style>
  html{
    font-size: 24px;
  }
</style>
