import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ko from '../locales/ko.json';
import en from '../locales/en.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'ko',
  fallbackLocale: 'ko',
  messages: { en, ko },
});
