<template>
  <div style='overflow:clip'>
    <v-progress-circular
    v-if="isLoading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <div v-if="!isLoading && isDrawSimulation" style="text-align: center">
      <v-icon v-if="!isPlay" @click="isPlay = true">mdi-play</v-icon>
      <v-icon v-if="isPlay" @click="isPlay = false">mdi-pause</v-icon>
      <v-slider
        v-model="currentTime"
        :max="maxTime"
      ></v-slider>
    </div>

<!--    <v-lazy  v-model="isActive"-->
<!--        transition="fade-transition">-->
    <div style="position: relative; text-align: left; white-space: normal;" class="parent" id="parent">
      <div style="position: absolute; overflow: visible" id="konva" />
    </div>

<!--    </v-lazy>-->
  </div>
</template>

<script>
import Konva from 'konva';
import { getTextByWordAoiId } from '../../utils/PassageTextUtils';

function range(end, start) {
  const array = [];
  for (let i = end; i >= start; i -= 1) {
    array.push(i);
  }
  return array;
}

export default {
  name: 'TextViewer',
  props: {
    profitRatio: {
      type: Number,
      default: 0.7,
    },
    isText: {
      type: Boolean,
      default: true,
    },
    isDrawHeatmap: {
      type: Boolean,
      default: false,
    },
    isDrawScanPath: {
      type: Boolean,
      default: false,
    },
    isDrawSimulation: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default:
        "무지개는 빛이 공기 중에 떠 있는 비,물보라,안개와 같은 작은 물방울을 비출 때, 굴절,반사되어 나타나는 반원 모양의 일곱 가지 색선을 일컫는다. 빛은 여러 가지 색깔이 혼합되어 있는데, 색깔마다 굴절 정도가 다르다. 그래서 굴절 정도가 가장 작은 빨강부터 가장 큰 보라까지 일곱 가지 색깔로 분산되어 나와 무지개가 만들어진다. 가장 밝고 흔히 관찰되는 무지개는 빛이 물방울 안에서 한 번 반사된 후 밖으로 나와 만들어진 '1차 무지개’이다. 이 무지개의 내부 반사 각도는 색깔마다 조금씩 다르지만 대체로 42도 정도이고, 색깔의 배열은 안쪽에서부터 바깥쪽으로 보라·남색·파랑·초록·노랑·주황·빨강으로 나타난다. 가끔 '2차 무지개’도 관찰 되는데, 이것은 1차 무지개보다 색이 희미하고 색의 배열은 반대이다. 2차 무지개는 빛이 물방울 안에서 두 번 내부 반사하여 생기는데, 그 각도는 약 50도여서 1차 무지개의 위쪽에 나타난다. 이처럼 1차 무지개와 2차 무지개가 모두 나타나면 쌍무지개가 된다. 세 번 이상의 다단계 내부 반사를 통해 더 고차의 무지개가 생길 수 있으나, 이 무지개는 매우 희미해서 거의 관찰되지 않는다.",
    },
    wordAoi: {
      type: Array,
      default: () => [],
    },
    typedFixation: {
      type: Array,
      default: () => [],
    },
    rawFixation: {
      type: Array,
      default: () => [],
    },
    rawGazePoint: {
      type: Array,
      default: () => [],
    },
    screenWidth: {
      type: Number,
    },
    screenHeight: {
      type: Number,
    },
    textBox: {
      type: Object,
    },
  },
  beforeMount() {
    clearInterval(this.playInterval);
  },
  data() {
    return {
      playInterval: null,
      currentTime: 0,
      maxTime: 0,
      isPlay: false,
      resizeDone: '',
      id: '',
      fontSize: 0,
      inlineWordCount: [],
      stage: null,
      layer: null,
      circles: [],
      lines: [],
      isLoading: true,
      isActive: false,
    };
  },
  async mounted() {
    this.id = Math.floor(Math.random() * 100000);
    this.$nextTick(() => {
      this.$el.querySelector('#parent').id = `parent_${this.id}`;
      this.$el.querySelector('#konva').id = `konva_${this.id}`;
      this.draw();
    });
    const result = [];
    let timestamp = 0;
    for (let i = 0; i < this.wordAoi.length; i += 1) {
      const duration = Math.floor(Math.random() * 1000 + 100);
      if (Math.random() > 0.6) {
        result.push({
          line: this.wordAoi[i].line,
          order: this.wordAoi[i].order,
          duration,
          timestamp,
        });
      }
      timestamp += duration;
      timestamp += Math.random() * 200;
    }
    console.log(result);
  },
  watch: {
    wordAoi() {
      // this.draw();
    },
    async isActive() {
      // this.draw();
      await this.$nextTick();
      this.id = Math.floor(Math.random() * 1000000000);
      this.$el.querySelector('#parent').id = `parent_${this.id}`;
      this.$el.querySelector('#konva').id = `konva_${this.id}`;
      setTimeout(() => {
        this.draw();
      }, 1000);
    },
  },
  methods: {
    async draw() {
      if (this.isText) {
        this.setTextToViewer();
        this.setProfitFontSizeForText(this.profitRatio);
      } else {
        this.setWordAoiToViewer();
        await this.setProfitFontSizeForWordAoi();
        if (this.isDrawHeatmap) {
          this.drawHeatMap();
        } else if (this.isDrawScanPath) {
          if (this.rawFixation.length > 0) { this.drawRawScanPath(); } else this.drawScanPath();
        }
      }
      this.isLoading = false;

      if (this.isDrawSimulation && this.typedFixation.length > 0) {
        clearInterval(this.playInterval);
        this.maxTime = this.typedFixation[this.typedFixation.length - 1].timestamp + this.typedFixation[this.typedFixation.length - 1].duration
         - this.typedFixation[0].timestamp;

        let lastHighlighted = [];
        let beforeIndex = -1;

        this.playInterval = setInterval(() => {
          if (this.isPlay) {
            const targetTime = this.typedFixation[0].timestamp + this.currentTime;
            for (let i = 0; i < this.typedFixation.length; i += 1) {
              if (this.typedFixation[i].timestamp <= targetTime && targetTime <= this.typedFixation[i].timestamp + this.typedFixation[i].duration) {
                let targetSpanLeft2 = false;
                let targetSpanRight2 = false;

                if (i !== 0 && i !== this.typedFixation.length - 1) {
                  const pastFixationLine = this.typedFixation[i - 1].line;
                  const pastFixationOrder = this.typedFixation[i - 1].order;
                  const nextFixationLine = this.typedFixation[i + 1].line;
                  const nextFixationOrder = this.typedFixation[i + 1].order;

                  if (pastFixationLine === this.typedFixation[i].line) {
                    const pastDiff = this.typedFixation[i].order - pastFixationOrder;
                    if (pastDiff > 1) {
                      targetSpanLeft2 = this.getElementById(`viewer_wordaoi_${this.typedFixation[i].line}_${this.typedFixation[i].order - 2}`);
                    }
                  }

                  if (nextFixationLine === this.typedFixation[i].line) {
                    const nextDiff = nextFixationOrder - this.typedFixation[i].order;
                    if (nextDiff > 1) {
                      targetSpanRight2 = this.getElementById(`viewer_wordaoi_${this.typedFixation[i].line}_${this.typedFixation[i].order + 2}`);
                    }
                  }
                }

                const targetSpanCenter = this.getElementById(`viewer_wordaoi_${this.typedFixation[i].line}_${this.typedFixation[i].order}`);
                const targetSpanLeft = this.getElementById(`viewer_wordaoi_${this.typedFixation[i].line}_${this.typedFixation[i].order - 1}`);
                const targetSpanRight = this.getElementById(`viewer_wordaoi_${this.typedFixation[i].line}_${this.typedFixation[i].order + 1}`);

                if (beforeIndex !== i) {
                  if (lastHighlighted.length > 0) {
                    lastHighlighted.forEach((item) => {
                      if (item.style) {
                        // eslint-disable-next-line no-param-reassign
                        item.style.backgroundColor = 'transparent';
                      }
                    });
                  }
                }
                beforeIndex = i;

                targetSpanCenter.style.backgroundColor = '#fdd835';

                if (targetSpanLeft) {
                  targetSpanLeft.style.backgroundColor = '#fff176';
                }

                if (targetSpanRight) {
                  targetSpanRight.style.backgroundColor = '#fff176';
                }

                if (targetSpanLeft2) {
                  targetSpanLeft2.style.backgroundColor = '#fff9c4';
                }

                if (targetSpanRight2) {
                  targetSpanRight2.style.backgroundColor = '#fff9c4';
                }

                lastHighlighted = [];
                lastHighlighted.push(targetSpanCenter);
                if (targetSpanLeft) {
                  lastHighlighted.push(targetSpanLeft);
                }
                if (targetSpanRight) {
                  lastHighlighted.push(targetSpanRight);
                }
                if (targetSpanLeft2) {
                  lastHighlighted.push(targetSpanLeft2);
                }
                if (targetSpanRight2) {
                  lastHighlighted.push(targetSpanRight2);
                }

                break;
              }
            }
            this.currentTime += 33;
          }
        }, 33);
      }
    },
    getElementById(elementId) {
      return document.getElementById(`${elementId}_${this.id}`);
    },
    getWordAoiRect(line, order) {
      const targetSpan = this.getElementById(`viewer_wordaoi_${line}_${order}`);
      const parentDiv = this.getElementById('parent');
      if (targetSpan) {
        const parentBoundingRect = parentDiv.getBoundingClientRect();

        const targetSpanBoundingRect = targetSpan.getBoundingClientRect();

        const wordAoiRect = {
          x: targetSpanBoundingRect.x - parentBoundingRect.x,
          y: targetSpanBoundingRect.y - parentBoundingRect.y,
          width: targetSpanBoundingRect.width,
          height: targetSpanBoundingRect.height,
        };

        return wordAoiRect;
      }
      return false;
    },
    setProfitFontSizeForText(profitRatio) {
      const profitHeight = window.innerHeight * profitRatio;
      const readingText = this.getElementById('parent');
      for (let i = 5; i < 100; i += 1) {
        readingText.style.fontSize = `${i}px`;
        if (readingText.clientHeight > profitHeight) {
          readingText.style.fontsize = `${i - 1}px`;
          this.fontSize = i - 1;
          break;
        }
      }
    },
    async setProfitWidthForWordAoi() {
      let foundProfitWidth = false;
      const readingText = this.getElementById('parent');
      let widthRatio = 100;
      await this.$nextTick();
      while (!foundProfitWidth) {
        if (readingText) {
          readingText.style.width = `${widthRatio}%`;
        }
        widthRatio += 10;
        let currentLine = 0;
        let currentY = -100;
        for (let i = 0; i < this.wordAoi.length; i += 1) {
          const targetSpan = this.getElementById(
            `viewer_wordaoi_${this.wordAoi[i].line}_${this.wordAoi[i].order}`,
          );
          const targetSpanBoundingRect = targetSpan.getBoundingClientRect();
          const { y } = targetSpanBoundingRect;
          if (currentLine === this.wordAoi[i].line && currentY !== y) {
            break;
          }
          currentLine = this.wordAoi[i].line;
          currentY = y;
          if (i === this.wordAoi.length - 1) {
            foundProfitWidth = true;
          }
        }
      }
    },
    async setProfitFontSizeForWordAoi() {
      let foundProfitSize = false;
      const readingText = this.getElementById('parent');

      const params = range(30, 10); // [...Array(100).keys()].reverse();
      // eslint-disable-next-line no-restricted-syntax
      for await (const j of params) {
        if (foundProfitSize) {
          // const fontSize = j - 1;
          break;
        } else if (j === params[params.length - 1]) {
          this.setProfitWidthForWordAoi();
        }
        await this.$nextTick();
        if (readingText) {
          readingText.style.fontSize = `${j}px`;
        }

        let currentLine = 0;
        let currentY = -100;

        for (let i = 0; i < this.wordAoi.length; i += 1) {
          const targetSpan = this.getElementById(
            `viewer_wordaoi_${this.wordAoi[i].line}_${this.wordAoi[i].order}`,
          );
          const targetSpanBoundingRect = targetSpan.getBoundingClientRect();
          const { y } = targetSpanBoundingRect;

          if (currentLine === this.wordAoi[i].line && currentY !== y) {
            break;
          }
          currentLine = this.wordAoi[i].line;
          currentY = y;

          if (i === this.wordAoi.length - 1) {
            foundProfitSize = true;
          }
        }
      }
    },
    setTextToViewer() {
      try {
        const arrPlainText = this.text.split(' ');
        let spanHtml = '';
        for (let i = 0; i < arrPlainText.length; i += 1) {
          spanHtml += `<span id='viewer_text_${i}_${this.id}'>${arrPlainText[i]}</span> `;
        }
        const targetParent = this.getElementById('parent');
        targetParent.innerHTML = spanHtml;
      } catch (e) {
        console.log(e);
      }
    },
    setWordAoiToViewer() {
      let spanHtml = '';
      let currentLine = 1;
      let inlineWordCount = 0;

      for (let i = 0; i < this.wordAoi.length; i += 1) {
        const { line, order, word } = this.wordAoi[i];
        if (currentLine !== line || i === this.wordAoi.length - 1) {
          if (i !== this.wordAoi.length - 1) {
            spanHtml += '<br>';
          }
          this.inlineWordCount.push(inlineWordCount);
          inlineWordCount = 0;
        }
        spanHtml += `<span id='viewer_wordaoi_${line}_${order}_${this.id}'>${word}</span><span> </span> `;
        currentLine = line;
        inlineWordCount += 1;
      }
      getTextByWordAoiId('viewer_wordaoi_', this.wordAoi);
      this.getElementById('parent').innerHTML += spanHtml;
    },
    drawHeatMap() {
      this.removeAll();

      let averageFixationDuration = 0;

      this.typedFixation.forEach((item) => {
        averageFixationDuration += item.duration / this.typedFixation.length;
      });

      const MAX_DURATION = averageFixationDuration * 2;
      let currentLine = 0;

      let inlineIntensityArray = [];

      for (let i = 0; i < this.typedFixation.length; i += 1) {
        if (currentLine !== this.typedFixation[i].line || i === this.typedFixation.length - 1) {
          for (let j = 0; j < inlineIntensityArray.length; j += 1) {
            // do render
            const intensity = Math.max(0.1, Math.min(inlineIntensityArray[j] / MAX_DURATION, 1));
            const targetSpan = this.getElementById(`viewer_wordaoi_${currentLine}_${j + 1}`);
            if (targetSpan) {
              targetSpan.style.background = `linear-gradient(to left, rgba(54,162,235,0.1), rgba(54,162,235,${intensity}), rgba(54,162,235,0.1))`;
            }
          }
          // clear
          const { line } = this.typedFixation[i];
          inlineIntensityArray = Array(this.inlineWordCount[line - 1]).fill(0);
        }
        currentLine = this.typedFixation[i].line;

        const targetIndex = this.typedFixation[i].order - 1;

        inlineIntensityArray[targetIndex] += this.typedFixation[i].duration;

        if (targetIndex > 1) {
          inlineIntensityArray[targetIndex - 1] += this.typedFixation[i].duration / 4;
        }

        if (targetIndex < inlineIntensityArray.length - 1) {
          inlineIntensityArray[targetIndex + 1] += this.typedFixation[i].duration / 4;
        }
      }
    },
    removeAll() {
      if (this.stage) {
        this.stage.destroyChildren();
        this.layer = new Konva.Layer();
        this.stage.add(this.layer);
      }

      for (let i = 0; i < this.inlineWordCount.length; i += 1) {
        for (let j = 1; j < this.inlineWordCount[i] + 1; j += 1) {
          const targetSpan = this.getElementById(`viewer_wordaoi_${i + 1}_${j}`);
          targetSpan.style.background = 'rgba(0,0,0,0)';
        }
      }
    },
    drawRawScanPath() {
      this.removeAll();
      if (!this.stage) {
        const stage = new Konva.Stage({
          container: `konva_${this.id}`,
          width: Math.floor(this.getElementById('parent').offsetWidth),
          height: Math.floor(this.getElementById('parent').offsetHeight),
          draggable: false,
        });

        this.stage = stage;
        this.layer = new Konva.Layer();

        this.stage.add(this.layer);

        // const vue = this;
        // window.onresize = function () {
        //   clearTimeout(this.doit);
        //   this.doit = setTimeout(() => {
        //     if (vue.isText) {
        //       vue.setProfitFontSizeForText(vue.profitRatio);
        //     } else {
        //       vue.setProfitFontSizeForWordAoi();
        //       if (vue.isDrawHeatmap) {
        //         vue.drawHeatMap();
        //       } else if (vue.isDrawScanPath) {
        //         vue.drawScanPath();
        //       }
        //     }
        //   }, 1000);
        // };
      }

      this.circles = [];
      this.lines = [];

      // this.fitStageIntoParentContainer();

      this.layer.clear();

      for (let i = 0; i < this.rawGazePoint.length; i += 1) {
        const rawX = this.rawGazePoint[i].x;
        const rawY = this.rawGazePoint[i].y;

        const ratioX = (rawX - this.textBox.x) / this.textBox.width;
        const ratioY = (rawY - this.textBox.y) / this.textBox.height;

        const centerX = ratioX * this.getElementById('parent').offsetWidth;
        const centerY = ratioY * this.getElementById('parent').offsetHeight;

        const circle = new Konva.Circle({
          x: centerX,
          y: centerY,
          fill: 'rgba(255,255,255,0.3)',
          stroke: 'rgba(11,11,11,1)',
          strokeWidth: 2,
          radius: 2,
          draggable: true,
        });
        this.layer.add(circle);
      }

      for (let i = 0; i < this.rawFixation.length; i += 1) {
        const rawX = this.rawFixation[i].x;
        const rawY = this.rawFixation[i].y;

        const ratioX = (rawX - this.textBox.x) / this.textBox.width;
        const ratioY = (rawY - this.textBox.y) / this.textBox.height;

        const centerX = ratioX * this.getElementById('parent').offsetWidth;
        const centerY = ratioY * this.getElementById('parent').offsetHeight;

        let averageFixationDuration = 0;

        this.typedFixation.forEach((item) => {
          averageFixationDuration += item.duration / this.typedFixation.length;
        });

        const MAX_DURATION = averageFixationDuration * 2;
        const MAX_RADIUS = this.getElementById('parent').offsetWidth / 40;
        const MIN_RADIUS = this.getElementById('parent').offsetWidth / 80;

        const circle = new Konva.Circle({
          x: centerX,
          y: centerY,
          fill: 'rgba(54,162,235,0.5)',
          stroke: 'rgba(54,162,235,0.6)',
          strokeWidth: 2,
          radius: Math.max(MIN_RADIUS, Math.min(MAX_RADIUS, MAX_RADIUS * (this.rawFixation[i].duration / MAX_DURATION))),
          draggable: true,
        });

        this.layer.add(circle);

        circle.originX = centerX;
        circle.originY = centerY;

        circle.start = this.rawFixation[i].timestamp;
        circle.end = this.rawFixation[i].timestamp + this.rawFixation[i].duration;

        this.layer.add(circle);
        this.circles.push(circle);
      }

      for (let i = 0; i < this.circles.length; i += 1) {
        if (i > 0) {
          const line = new Konva.Line({
            points: [
              this.circles[i - 1].originX,
              this.circles[i - 1].originY,
              this.circles[i].originX,
              this.circles[i].originY,
            ],
            stroke: 'rgba(54,162,235,0.9)',
            strokeWidth: 3,
            lineCap: 'round',
            lineJoin: 'round',
          });
          this.layer.add(line);
          this.lines.push(line);
        }
      }
    },
    drawScanPath() {
      this.removeAll();
      if (!this.stage) {
        const stage = new Konva.Stage({
          container: `konva_${this.id}`,
          width: Math.floor(this.getElementById('parent').offsetWidth),
          height: Math.floor(this.getElementById('parent').offsetHeight),
          draggable: false,
        });

        this.stage = stage;
        this.layer = new Konva.Layer();

        this.stage.add(this.layer);

        const vue = this;
        window.onresize = function () {
          clearTimeout(this.doit);
          this.doit = setTimeout(() => {
            if (vue.isText) {
              vue.setProfitFontSizeForText(vue.profitRatio);
            } else {
              vue.setProfitFontSizeForWordAoi();
              if (vue.isDrawHeatmap) {
                vue.drawHeatMap();
              } else if (vue.isDrawScanPath) {
                vue.drawScanPath();
              }
            }
          }, 1000);
        };
      }

      this.circles = [];
      this.lines = [];

      // this.fitStageIntoParentContainer();

      this.layer.clear();

      for (let i = 0; i < this.typedFixation.length; i += 1) {
        const targetRect = this.getWordAoiRect(
          this.typedFixation[i].line,
          this.typedFixation[i].order,
        );
        if (!targetRect) {
          // eslint-disable-next-line no-continue
          continue;
        }

        const centerX = targetRect.x + targetRect.width / 2;
        const centerY = targetRect.y + targetRect.height / 2;

        let averageFixationDuration = 0;

        this.typedFixation.forEach((item) => {
          averageFixationDuration += item.duration / this.typedFixation.length;
        });

        const MAX_DURATION = averageFixationDuration * 2;
        const MAX_RADIUS = this.getElementById('parent').offsetWidth / 40;
        const MIN_RADIUS = this.getElementById('parent').offsetWidth / 80;

        const circle = new Konva.Circle({
          x: centerX,
          y: centerY,
          fill: 'rgba(54,162,235,0.5)',
          stroke: 'rgba(54,162,235,0.6)',
          strokeWidth: 2,
          radius: Math.max(MIN_RADIUS, Math.min(MAX_RADIUS, MAX_RADIUS * (this.typedFixation[i].duration / MAX_DURATION))),
          draggable: true,
        });

        circle.originX = centerX;
        circle.originY = centerY;

        circle.start = this.typedFixation[i].timestamp;
        circle.end = this.typedFixation[i].timestamp + this.typedFixation[i].duration;

        this.layer.add(circle);
        this.circles.push(circle);
      }

      for (let i = 0; i < this.circles.length; i += 1) {
        if (i > 0) {
          const line = new Konva.Line({
            points: [
              this.circles[i - 1].originX,
              this.circles[i - 1].originY,
              this.circles[i].originX,
              this.circles[i].originY,
            ],
            stroke: 'rgba(54,162,235,0.9)',
            strokeWidth: 3,
            lineCap: 'round',
            lineJoin: 'round',
          });
          this.layer.add(line);
          this.lines.push(line);
        }
      }
    },

    // fitStageIntoParentContainer() {
    //   const container = this.getElementById('parent');
    //
    //   // now we need to fit stage into parent container
    //   const containerWidth = container.offsetWidth;
    //
    //   // but we also make the full scene visible
    //   // so we need to scale all objects on canvas
    //   const scale = containerWidth / window.screen.width;
    //
    //   this.stage.width(window.innerWidth * scale);
    //   this.stage.height(window.innerHeight * scale);
    //   this.stage.scale({ x: scale, y: scale });
    // },
  },
};
</script>

<style scoped>
.parent {
  line-height: 250%;
  word-break: keep-all;
}
</style>
