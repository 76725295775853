<template>
<v-container class="d-flex flex-column" fluid fill-height>
    <v-dialog v-model="showDialog" max-width="300">
      <TestStartInfoInputDialog
        @cancelDialog="hideAnnounceDialog"
        @confirmDialog="startTest"
        v-if="showDialog"
      >
      </TestStartInfoInputDialog>
    </v-dialog>
    <v-card width="100%" class="mb-10">
      <v-data-table
        :headers="headers"
        :items="textList"
        hide-default-footer
        mobile-breakpoint="0"
        disable-pagination
      >
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.actions="{ item }">
          <v-icon @click="showAnnounceDialog(item)">mdi-play-circle</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import TestStartInfoInputDialog from '../components/TestStartInfoInputDialog.vue';
import { hostUrl } from '../utils/ConnectionUtils';

export default {
  name: 'SentenceTestSelectView',
  mounted() {
    this.fetchPassages();
  },
  data() {
    return {
      showDialog: false,
      textList: [],
      selectedTest: {},
      headers: [
        {
          text: this.$t('labelText.level'),
          value: 'level',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labelText.textTitle'),
          value: 'title',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labelText.startTest'),
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
    };
  },
  methods: {
    async fetchPassages() {
      try {
        this.isLoading = true;
        const response = await axios.get(`${hostUrl}/api/passage`);
        if (response.status === 200) {
          this.textList = this.sortTextList(response.data.passages);
        }
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
    sortTextList(list) {
      return list.sort((a1, a2) => a1.level - a2.level);
    },
    showAnnounceDialog(item) {
      this.selectedTest = item;
      this.showDialog = true;
    },
    hideAnnounceDialog() {
      this.showDialog = false;
    },
    startTest({
      name, age, gender, type,
    }) {
      // eslint-disable-next-line no-underscore-dangle
      this.$store.commit('changePassageId', this.selectedTest._id);
      this.hideAnnounceDialog();
      console.log('startTest');

      console.log(name, age, gender, type);

      // this.$router.push('sentencetest');
      this.sendTestStart({
        name, age, gender, type,
      });
    },
    async sendTestStart({
      name, age, gender, type,
    }) {
      try {
        const response = await axios.post(
          `${hostUrl}/api/test`,
          {
            name, age, gender, level: this.selectedTest.level, title: this.selectedTest.title, type,
          },
          {
            'Content-Type': 'application/json',
          },
        );
        if (response.status === 200) {
          if (window.Unity) {
            // eslint-disable-next-line no-underscore-dangle
            window.Unity.call(`setVariable_testId_${response.data.test._id}`);
          }
          // eslint-disable-next-line no-underscore-dangle
          this.$store.commit('changeTestId', response.data.test._id);
          this.$router.push('sentencetest');
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    levelItems() {
      const items = [
        {
          level: this.$t('labelText.entireLevel'),
          value: 'All',
        },
      ];

      for (let i = 0; i < this.textList.length; i += 1) {
        items.push({
          level: this.textList[i].level,
          value: this.$t('labelText.levelLabel', { level: this.textList[i].level }),
        });
      }
      return items;
    },
  },
  components: { TestStartInfoInputDialog },
};
</script>
