<template>
  <v-container class="d-flex flex-column pt-15 pb-10" fill-height>
    <v-card class="d-flex flex-column pa-15" elevation="0" height="100%" width="100%">
      <div class="text-h3 bold mb-8">성별을 선택해 주세요</div>
      <v-container fill-height fluid>
        <v-row align="center"
               justify="center">
          <v-col>
            <v-select
              class="mb-15"
              large
              v-model="gender"
              :items="genders"
              label="Gender"
              required
              :rules="[(v) => !!v || '성별을 선택해 주세요']"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-spacer></v-spacer>
      <v-btn :disabled="isGenderNull" @click="directToAge()" class="" color="primary"
             height="5vh" >다음 </v-btn>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'RegisterGender',
  mounted() {
    this.sampleMethod();
  },
  beforeDestroy() {
    this.sampleMethod();
  },
  data() {
    return {
      sample: 1,
      gender: null,
      genders: ['남성', '여성'],
    };
  },
  methods: {
    sampleMethod() {
      this.sample += 1;
    },
    directToAge() {
      this.$store.commit('changeGender', this.gender);
      this.$router.push('registerage');
    },
  },
  watch: {
    sample(newVal, oldVal) {
      console.log('sample ', oldVal, ' change to ', newVal);
    },
  },
  computed: {
    sampleComputed() {
      return this.sample * 2;
    },
    isGenderNull() {
      return this.gender === null;
    },
  },
};
</script>
