<template>
  <div class="demo js-demo">
    <v-btn v-if="canSkip && $i18n.locale === 'en'" style="position:absolute; width:30%; bottom:20%; left:35%" outlined @click="doSkip"> Skip </v-btn>
    <v-btn v-if="canSkip && $i18n.locale !== 'en'" style="position:absolute; width:30%; bottom:20%; left:35%" outlined @click="doSkip"> 설명 넘어가기 </v-btn>
    <div class="demo-inner">
      <div id= "viewer" v-html="html" class="pl-2 pr-2">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestGuidanceView',
  async mounted() {
    this.setUnityListener();

    if (window.Unity) {
      window.Unity.call('startTracking');
      await setTimeout(() => {
        window.Unity.call('startCameraPreview');
      }, 1000);
    } else {
      this.startTestGuide();
    }
    // await this.showText(this.$t('messageText.hello'), 3000);
    // await this.showText(this.$t('messageText.announceStartTest'), 4500);
    // await this.showText(this.$t('messageText.announceCalibration'), 6000);
    // await this.showText('3', 1000);
    // await this.showText('2', 1000);
    // await this.showText('1', 1000);
    // this.$router.push('testReadingTextView');
  },
  beforeDestroy() {
    // this.store.state.isFirstLaunch = false;
  },
  data() {
    return {
      canSkip: false,
      skip: false,
      sample: 1,
      router: null,
      store: null,
      isLogin: false,
      model: 0,
      colors: ['primary', 'secondary', 'yellow darken-2', 'red', 'orange'],
      html: '',
    };
  },
  methods: {
    async showText(text, time) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve) => {
        const textLength = text.length;
        let paragraph = '';

        for (let index = 0; index < textLength; index += 1) {
          paragraph += `<span class="char-${index + 1} char" style="animation-delay:${index * 0.05}s;">${text[index]}</span> `;
        }
        this.html = paragraph;

        document.querySelector('#viewer').classList.add('slide-in');
        document.querySelector('.js-demo').classList.add('is-animated');

        setTimeout(() => {
          document.querySelector('#viewer').classList.add('fade-out');
        }, time / 2);

        setTimeout(() => {
          document.querySelector('.js-demo').classList.remove('is-animated');
          document.querySelector('#viewer').classList.remove('slide-in');
          document.querySelector('#viewer').classList.remove('fade-out');
          this.html = '';
          resolve();
        }, time);
      });
    },
    setUnityListener() {
      const vue = this;
      window.onPreviewFinished = () => {
        vue.startTestGuide();
      };
      window.onBackButtonPressed = () => {
        this.$router.push('main');
      };
    },
    async startTestGuide() {
      this.canSkip = true;
      if (!this.skip) await this.showText(this.$t('messageText.hello'), 3000);
      if (!this.skip) await this.showText(this.$t('messageText.announceStartTest'), 4500);
      if (!this.skip) await this.showText(this.$t('messageText.announceCalibration'), 6000);
      this.canSkip = false;
      await this.showText('3', 1000);
      await this.showText('2', 1000);
      await this.showText('1', 1000);
      this.$router.push('testReadingTextView');
    },
    doSkip() {
      this.skip = true;
      this.canSkip = false;
    },
  },
};
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  body  {
    align-items: center;
    background: #212121;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    height: 100vh;
    justify-content: center;
    margin: 0;
    padding: 10px;
  }
  .demo {
    align-content: center;
    border: 1px solid #ab987a;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  .demo::before {
    color: #d8d8d8;
    content: 'START';
    display: block;
    font-size: 14px;
    left: 12px;
    position: absolute;
    top: 12px;
    transition: opacity 0.1s;
  }
  .demo.is-animated::before {
    opacity: 0;
  }
  .demo-inner {
    text-align: center;
  }
  ::v-deep .char{
    backface-visibility: hidden;
    color: #212121;
    display: inline-block;
    font-size: 5.5vw;
    font-weight: 600;
  }
  [data-animate='slide-in'] {
    overflow: hidden;
  }

  .is-animated .slide-in ::v-deep .char {
    animation: slideIn 0.8s cubic-bezier(0.23, 1, 0.32, 1) forwards;
    transform: translateY(100%);
  }

  .is-animated .fade-out ::v-deep .char {
    animation: fadeOut 0.8s cubic-bezier(0.23, 1, 0.32, 1) forwards;
    transform: translateY(0%);
  }
  @-moz-keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @-webkit-keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @-o-keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @-moz-keyframes fadeOut {
    0% {
      opacity: 1
    }
    100% {
      opacity: 0
    }
  }
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1
    }
    100% {
      opacity: 0
    }
  }
  @-o-keyframes fadeOut {
    0% {
      opacity: 1
    }
    100% {
      opacity: 0
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1
    }
    100% {
      opacity: 0
    }
  }

</style>
