<template>
  <v-container fluid fill-height>
    <div id="container" style="background-color: white; z-index:1">
    </div>
    <v-layout class="d-flex flex-column" fluid column fill-height style="">
      <v-flex xs8 id="textTemplate" fluid>
        <v-card class="pa-3" width="100%" height="100%" elevation="0">
          <div
            id="readingText"
            style="line-height: 300%; word-break: keep-all"
          ></div>
        </v-card>
      </v-flex>
      <v-flex class="d-flex flex-column mt-5" xs4>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$i18n.locale !== 'en'"
          width="40%"
          x-large
          @click="
            () => {
              stopSignal(`passageText${textIndex + 1}`);
              sendReadingData();
              directToQuestion();
            }
          "
          class="align-self-end"
          outlined
          v-show="textIndex !== -1"
          >다 읽었어요</v-btn
        >
        <v-btn
          v-if="$i18n.locale === 'en'"
          width="40%"
          large
          @click="
            () => {
              stopSignal(`passageText${textIndex + 1}`);
              sendReadingData();
              directToQuestion();
            }
          "
          class="align-self-end"
          outlined
          v-show="textIndex !== -1"
        >Finished</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Konva from 'konva';
// import html2canvas from 'html2canvas';
import { getTextByPassage } from '../utils/PassageTextUtils';
import { hostUrl } from '../utils/ConnectionUtils';
// eslint-disable-next-line import/no-unresolved
const axios = require('axios');

const SIZE = 15;

const template = document.createElement('template');

template.innerHTML = `
<style>
  :host, .dot {
    width: ${SIZE}px;
    height: ${SIZE}px;
  }

:host {
    --color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .dot {
    border-radius: 100%;
    background: var(--color);;
    position: absolute;
    transition: height 1s ease-out;
  }

  .rotating {
    height: ${SIZE - 5}px;
  }
</style>

<div id="main-dot" class="dot" style="">

`;

class DotElement extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({ mode: 'open' });
    shadow.appendChild(template.content.cloneNode(true));
    this.value = 0;
    // this.startRotate();
    // this.setup();
  }

  currentRotateAnim = null;

  // setup() {
  //   this.addEventListener('mouseenter', () => {
  //     if (!this.isExploded) {
  //       this.startRotate();
  //       this.value += 0.1;
  //       console.log(this.value);
  //       this.setPlaybackRate(this.value);
  //       if (this.value > 1) {
  //         this.explode();
  //       }
  //     }
  //   });
  //
  //   this.addEventListener('mouseleave', () => {
  //     // this.stopRotate();
  //   });
  // }

  setVue(vue) {
    this.vue = vue;
  }

  // eslint-disable-next-line no-unused-vars
  setProgress(progress, isCollecting, isFirstPoint) {
    // this.unityLogger(`setProgress ${progress},${isCollecting}`);
    this.value = progress;

    if (this.value < 0.1 && isCollecting) {
      this.stopRotate();
    } else if (this.value < 0.999999 && isCollecting) {
      this.startRotate();
      this.currentRotateAnim.playbackRate = this.value * 2;
    }
    // else if (!this.isExploded && this.value === 1 && !isFirstPoint) {
    //   this.explode();x
    // }
  }

  setVisible(visible) {
    if (visible) {
      this.$mainDot.style.display = 'block';
    } else {
      this.$mainDot.style.display = 'none';
    }
  }

  createDot(size) {
    const $dot = document.createElement('div');
    $dot.classList.add('dot');
    $dot.style.width = `${size}px`;
    $dot.style.height = `${size}px`;

    const randomColor = Math.floor(Math.random() * 16777215).toString(16);

    $dot.style.background = `#${randomColor}`;

    this.shadowRoot.appendChild($dot);
    return $dot;
  }

  explode() {
    this.vue.unityLogger('explode');

    this.isExploded = true;
    this.value = 0;
    this.$mainDot.classList.remove('rotating');

    if (this.currentRotateAnim !== null) {
      this.currentRotateAnim.cancel();
      this.currentRotateAnim = null;
    }

    this.$mainDot.style.display = 'none';

    const radius = 150;
    const dotCount = 100;

    for (let i = 0; i < dotCount; i += 1) {
      // eslint-disable-next-line no-mixed-operators
      const $dot = this.createDot(Math.round((Math.random() * SIZE) / 2));
      const x = Math.round(Math.random() * (radius * 2) - radius);
      const y = Math.round(Math.random() * (radius * 2) - radius);

      $dot.animate(
        [
          {
            transform: 'translate(0, 0)',
            opacity: Math.random() * 0.5 + 0.5,
          },
          {
            transform: `translate(${x}px, ${y}px)`,
            opacity: 0,
          },
        ],
        {
          duration: 1500,
          fill: 'both',
          easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
      );
    }

    const that = this;

    setTimeout(() => {
      // 각 에니메이션 끝날때 onExploded가 계속 불렸던 문제 해결
      // $dot.remove();
      that.$mainDot.style.display = 'none';
      that.vue.onExploded();
      that.isExploded = false;
    }, 1500);
  }

  get $mainDot() {
    return this.shadowRoot.querySelector('#main-dot');
  }

  setPlaybackRate(rate) {
    this.playbackRate = rate;
    if (this.currentRotateAnim !== null) {
      this.currentRotateAnim.playbackRate = this.playbackRate;
      console.log('rate', this.currentRotateAnim.playbackRate);
    }
  }

  stopRotate() {
    if (this.currentRotateAnim !== null) {
      this.currentRotateAnim.cancel();
      this.currentRotateAnim = null;
    }

    this.$mainDot.classList.remove('rotating');
  }

  startRotate() {
    this.$mainDot.style.display = 'block';

    if (this.currentRotateAnim !== null) {
      return;
    }

    this.$mainDot.classList.add('rotating');
    this.currentRotateAnim = this.$mainDot.animate(
      [
        {
          transform: 'rotate(0deg)',
        },
        {
          transform: `rotate(${360 * 2000}deg)`,
        },
      ],
      {
        duration: 1000 * 500,
        fill: 'both',
        // easing: 'ease-in',
        loop: true,
      },
    );

    this.currentRotateAnim.playbackRate = this.value;

    // this.currentRotateAnim.onfinish = () => {
    //   this.$mainDot.classList.remove('rotating');
    //   this.startRotate();
    // };
  }

  // stopRotate() {
  //   const dec = () => {
  //     if (this.currentRotateAnim.playbackRate > 0) {
  //       this.currentRotateAnim.playbackRate -= 0.02;
  //       requestAnimationFrame(dec);
  //     } else {
  //       this.currentRotateAnim.cancel();
  //       this.currentRotateAnim = null;
  //     }
  //   };
  //
  //   dec();
  //
  //   this.$mainDot.classList.remove('rotating');
  // }
}
window.customElements.define('dot-element', DotElement);

const tmpText = [
  '읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문',
  '222222 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 222222 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 222222 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 222222 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문 읽기 지문',
];

let left = 0;
let top = 0;
let width = 0;
let height = 0;

function getCalibrationCoordinate(value) {
  const content = document.querySelector('#readingText');
  left = content.offsetLeft;
  top = content.offsetTop;
  width = content.offsetWidth;
  height = content.offsetHeight;
  switch (value) {
    case 1:
      return {
        x: width,
        y: top,
      };
    case 2:
      return {
        x: width,
        y: height,
      };
    case 3:
      return {
        x: left,
        y: height,
      };
    case 4:
      return {
        x: left,
        y: top,
      };
    default: {
      return { x: 0, y: 0 };
    }
  }
}

function tmpCalibrationTimeout(value) {
  const coordinate = getCalibrationCoordinate(value);
  const circle = new Konva.Circle({
    x: coordinate.x,
    y: coordinate.y,
    fill: 'rgba(255,0,0,0.3)',
    stroke: 'rgba(255,0,0,1)',
    strokeWidth: 2,
    radius: 10,
  });
  return circle;
}

function startSignal(value) {
  if (window.Unity) {
    window.Unity.call(`startSignal_${value}`);
  }
}

export default {
  name: 'TestReadingTextView',
  beforeDestroy() {
    this.stopRecording();
  },
  methods: {
    directToQuestion() {
      this.stopRecording();
      this.stopTracking();
      this.$router.push('testQuestionView');
    },
    getText() {
      this.text = tmpText[this.textIndex];
    },
    setKonvaCalibration() {
      const layer = new Konva.Layer();
      let count = 0;
      const timer = setInterval(() => {
        if (count > 3) {
          this.startRecording();

          const that = this;
          setTimeout(() => {
            that.showKonva = false;
            layer.removeChildren();
            clearInterval(timer);
            that.konvaStage.container().style.backgroundColor = null;

            that.konvaStage.clear();
          }, 500);

          // setTimeout(() => {
          //   html2canvas(document.body).then((canvas) => {
          //     // console.log(canvas.toDataURL('image/png'));
          //     // const a = document.createElement('a');
          //     // a.download = 'ss.png';
          //     // a.href = canvas.toDataURL('image/png');
          //     // a.click();
          //     this.screenShot = canvas.toDataURL('image/png');
          //   });
          // }, 2000);
        }
        layer.removeChildren();
        if (count < 4) layer.add(tmpCalibrationTimeout(count));
        count += 1;
      }, 1500);
      return layer;
    },

    setKonvaState() {
      this.showKonva = true;
      const tmpWidth = document.querySelector('#textTemplate').offsetWidth; // document.querySelector('#card1').offsetWidth
      const tmpheight = document.querySelector('#textTemplate').offsetHeight; // document.querySelector('#card1').offsetHeight
      if (this.konvaStage === null || this.konvaStage === undefined) {
        this.konvaStage = new Konva.Stage({
          container: 'konva_container',
          width: tmpWidth,
          height: tmpheight,
        });
      }

      this.konvaStage.clear();
      this.konvaStage.container().style.backgroundColor = '#fff';
      this.konvaStage.add(this.setKonvaCalibration());
    },
    setLineHeight() {
      const profitHeight = window.innerHeight * 0.7;
      const readingText = document.querySelector('#readingText');
      for (let i = 10; i < 29; i += 1) {
        readingText.style.fontSize = `${i}px`;
        if (readingText.clientHeight > profitHeight) {
          readingText.style.fontsize = `${i - 1}px`;
          this.fontSize = i - 1;
          break;
        }
      }
      // TODO origin
      // startRecording();

      // this.setKonvaState();
    },
    stopSignal(value) {
      if (window.Unity) {
        window.Unity.call(`stopSignal_${value}`);
      }
    },
    async getPassage() {
      const { passageId } = this.$store.state;
      const response = await axios(
        `${hostUrl}/api/passage/${passageId}`,
      );
      return response.data.passage;
    },
    sendReadingData() {
      const { passageId } = this.$store.state;
      const vue = this;
      axios
        .post(
          `${hostUrl}/api/test/${this.$store.state.testId}`,
          {
            // .post(`http://localhost:8080/api/test/${this.$store.state.testId}`, {
            passageId,
            deviceInformation: {
              screenResolution: {
                width: window.screen.width * window.devicePixelRatio,
                height: window.screen.height * window.devicePixelRatio,
              },
            },
            textMetadata: {
              font: 'Arial',
              fontSize: this.fontSize * window.devicePixelRatio,
              lineHeight: this.fontSize * 2 * window.devicePixelRatio,
              textBox: {
                x: left * window.devicePixelRatio,
                y: top * window.devicePixelRatio,
                width: width * window.devicePixelRatio,
                height: height * window.devicePixelRatio,
              },
            },
            boundaryPoint: this.boundaryPoint,
            wordAoi: this.getWordData(),
            screenShot: this.screenShot,
          },
        )
        .then((response) => {
          vue.error = JSON.stringify(response);
        })
        // eslint-disable-next-line no-return-assign
        .catch((error) => (vue.error = error));
    },
    getBoundary(index) {
      const wordAois = this.getWordData();
      if (wordAois.length > 0) {
        const minX = wordAois[0].wordBox.x / window.devicePixelRatio + this.fontSize;
        const maxX = Math.max(...wordAois.map((item) => item.wordBox.x / window.devicePixelRatio + item.wordBox.width / 2 / window.devicePixelRatio));
        const minY = wordAois[0].wordBox.y / window.devicePixelRatio + wordAois[0].wordBox.height / 2 / window.devicePixelRatio;
        const maxY = Math.max(...wordAois.map((item) => item.wordBox.y / window.devicePixelRatio + item.wordBox.height / 2 / window.devicePixelRatio));

        if (index === 1) {
          return { x: maxX, y: minY };
        } if (index === 2) {
          return { x: maxX, y: maxY };
        }
        if (index === 3) {
          return { x: minX, y: maxY };
        }
        return { x: minX, y: minY };
      }
      return { x: 100, y: 100 };
    },
    getWordData() {
      const texts = this.text.split(' ');
      let line = 0;
      let offset = 0;
      let order = 1;
      const tmpArrWords = [];

      const content = document.querySelector('#readingText');
      const parentLeft = content.offsetLeft;
      const parentTop = content.offsetTop;

      for (let i = 0; i < texts.length; i += 1) {
        const wordObject = document.querySelector(`#textWord${i}`);
        const wordOffset = wordObject.offsetTop;
        if (wordOffset !== offset) {
          line += 1;
          offset = wordOffset;
          order = 1;
        }
        // console.log(`getWorldData word : ${wordObject.innerHTML}`);
        tmpArrWords.push({
          line,
          word: wordObject.innerHTML,
          wordBox: {
            x: (wordObject.offsetLeft + parentLeft) * window.devicePixelRatio,
            y: (wordObject.offsetTop + parentTop) * window.devicePixelRatio,
            width: wordObject.offsetWidth * window.devicePixelRatio,
            height: wordObject.offsetHeight * window.devicePixelRatio,
          },
          order,
        });
        order += 1;
      }

      return tmpArrWords;
    },
    setUnityListener() {
      const vue = this;
      window.onCalibrationFinished = () => {
        vue.onCalibrationFinished();
      };
      window.onCalibrationNextpoint = (x, y) => {
        vue.onCalibrationNextpoint(x, y);
      };
      window.onCalibrationProgress = (progress) => {
        vue.onCalibrationProgress(progress);
      };
      window.onGazeStreaming = (gazeInfo) => {
        vue.onGazeStreaming(gazeInfo);
      };
    },
    stopCalibration() {
      if (window.Unity) {
        window.Unity.call('stopCalibration');
      }
    },
    unityLogger(string) {
      if (window.Unity) {
        window.Unity.call(string);
      }
    },
    startCalibration() {
      if (window.Unity) {
        if (this.$store.state.calibrationMode === 'normal_calibration') {
          if (this.$store.state.calibrationPoint === 'five_point') {
            window.Unity.call('startFivePointCalibration');
          } else if (this.$store.state.calibrationPoint === 'six_point') {
            window.Unity.call('startCalibration');
          }
        } else if (this.$store.state.calibrationMode === 'easy_calibration') {
          if (this.$store.state.calibrationPoint === 'five_point') {
            window.Unity.call('startFivePointLowCalibration');
          } else if (this.$store.state.calibrationPoint === 'six_point') {
            window.Unity.call('startLowCalibration');
          }
        }
      }
    },
    startStreaming() {
      if (window.Unity) {
        window.Unity.call('startStreaming');
      }
    },
    startRecording() {
      if (window.Unity) {
        window.Unity.call('startRecording');
        this.isRecording = true;
      }
    },
    stopRecording() {
      if (window.Unity) {
        window.Unity.call('stopRecording');
      }
    },
    stopTracking() {
      if (window.Unity) {
        window.Unity.call('stopTracking');
      }
    },
    stopStreaming() {
      if (window.Unity) {
        window.Unity.call('stopStreaming');
      }
    },
    startLowCalibration() {
      if (window.Unity) {
        window.Unity.call('startLowCalibration');
      }
    },
    startCollectSamples() {
      if (window.Unity) {
        window.Unity.call('startCollectSamples');
        this.isCollecting = true;
      }
    },
    onCalibrationFinished() {
      this.isCollecting = false;
      this.isFirstPoint = false;
      const dotElement = document.querySelector('dot-element');
      this.boundaryStep = 1;
      dotElement.explode();
      this.$nextTick(() => {
        this.startBoundaryCalibration();
        // this.startRecording();
        // document.querySelector('#container').remove();
        // setTimeout(() => {
        //   html2canvas(document.body).then((canvas) => {
        //   // console.log(canvas.toDataURL('image/png'));
        //   // const a = document.createElement('a');
        //   // a.download = 'ss.png';
        //   // a.href = canvas.toDataURL('image/png');
        //   // a.click();
        //     this.screenShot = canvas.toDataURL('image/png');
        //   });
        // }, 2000);
      });
    },
    onCalibrationNextpoint(x, y) {
      this.isCollecting = false;
      const dotElement = document.querySelector('dot-element');
      this.nextPointX = `${x / window.devicePixelRatio - Math.floor(SIZE / 2)}px`;
      this.nextPointY = `${y / window.devicePixelRatio - Math.floor(SIZE / 2)}px`;

      const that = this;
      if (this.isFirstPoint) {
        dotElement.style.left = this.nextPointX;
        dotElement.style.top = this.nextPointY;
        dotElement.setVisible(true);
        setTimeout(() => {
          that.isFirstPoint = false;
          that.startCollectSamples();
        }, 600);
      } else {
        dotElement.explode();
      }
    },
    onCalibrationProgress(progress) {
      // this.unityLogger(`onCalibrationProgress ${progress}`);
      const dotElement = document.querySelector('dot-element');
      dotElement.setProgress(progress, this.isCollecting, this.isFirstPoint);
    },
    onGazeStreaming(gazeInfo) {
      if (this.boundaryStep < 1) {
        return;
      }
      if (gazeInfo) {
        if (this.isCollecting) {
          const dotElement = document.querySelector('dot-element');

          if (gazeInfo.trackingState < 2) {
            this.gazeCount += 1;

            this.boundaryPoint.push({
              target: {
                x: this.getBoundary(this.boundaryStep).x * window.devicePixelRatio,
                y: this.getBoundary(this.boundaryStep).y * window.devicePixelRatio,
              },
              gaze: {
                x: gazeInfo.x,
                y: gazeInfo.y,
              },
            });
          }

          if (this.gazeCount === 40) {
            this.gazeCount = 0;
            this.boundaryStep += 1;
            this.nextPointX = `${this.getBoundary(this.boundaryStep).x - Math.floor(SIZE / 2)}px`;
            this.nextPointY = `${this.getBoundary(this.boundaryStep).y - Math.floor(SIZE / 2)}px`;
            dotElement.explode();
            this.isCollecting = false;
          } else {
            dotElement.setProgress(this.gazeCount / 40, this.isCollecting, this.isFirstPoint);
          }
        }
      }
    },
    startBoundaryCalibration() {
      this.boundaryPoint = [];
      this.nextPointX = `${this.getBoundary(1).x - Math.floor(SIZE / 2)}px`;
      this.nextPointY = `${this.getBoundary(1).y - Math.floor(SIZE / 2)}px`;
      console.log(this.nextPointX, this.nextPointY);
      this.startStreaming();
    },
    onExploded() {
      const that = this;
      this.unityLogger('onExploded');
      if (this.boundaryStep === 0) {
        const dotElement = document.querySelector('dot-element');
        dotElement.style.left = this.nextPointX;
        dotElement.style.top = this.nextPointY;
        dotElement.setVisible(true);
        setTimeout(() => {
          that.startCollectSamples();
        }, 1200);
      } else if (this.boundaryStep === 5) {
        this.stopStreaming();
        this.startRecording();
        document.querySelector('#container').remove();
        // setTimeout(() => {
        //   html2canvas(document.body).then((canvas) => {
        //     // console.log(canvas.toDataURL('image/png'));
        //     // const a = document.createElement('a');
        //     // a.download = 'ss.png';
        //     // a.href = canvas.toDataURL('image/png');
        //     // a.click();
        //     this.screenShot = canvas.toDataURL('image/png');
        //   });
        // }, 2000);
      } else {
        const dotElement = document.querySelector('dot-element');
        dotElement.style.left = this.nextPointX;
        dotElement.style.top = this.nextPointY;
        dotElement.setVisible(true);
        setTimeout(() => {
          that.isCollecting = true;
        }, 1200);
      }
    },
    startWithoutEyeTracking() {
      document.querySelector('#container').remove();
      // setTimeout(() => {
      //   html2canvas(document.body).then((canvas) => {
      //     // console.log(canvas.toDataURL('image/png'));
      //     // const a = document.createElement('a');
      //     // a.download = 'ss.png';
      //     // a.href = canvas.toDataURL('image/png');
      //     // a.click();
      //     this.screenShot = canvas.toDataURL('image/png');
      //   });
      // }, 2000);
    },
  },
  async mounted() {
    document.querySelector('#readingText').innerHTML = '';
    this.error = this.$store.state.testId;
    const passage = await this.getPassage();
    this.pageText = passage.text;
    this.pageQuestions = passage.question;
    console.log(this.pageQuestions);
    this.$store.commit('changeQuestion', this.pageQuestions);
    // tmpVue.textIndex = 0;
    this.textIndex = 0;

    // window.onCalibrationFinished = () => {
    //   tmpVue.textIndex = 0;
    // };

    const myNode = document.querySelector('#container');

    while (myNode.firstChild) {
      myNode.removeChild(myNode.lastChild);
    }
    this.setUnityListener();
    const elem = document.createElement('dot-element');
    elem.setVue(this);
    elem.setVisible(false);
    document.querySelector('#container').appendChild(elem);
    this.isFirstPoint = true;
    this.isCollecting = false;

    const that = this;
    if (!window.Unity) {
      setTimeout(() => {
        that.startWithoutEyeTracking();
      }, 1000);
    } else if (this.$store.state.calibrationMode === 'no_calibration') {
      this.startRecording();
      document.querySelector('#container').remove();
      // setTimeout(() => {
      //   html2canvas(document.body).then((canvas) => {
      //     // console.log(canvas.toDataURL('image/png'));
      //     // const a = document.createElement('a');
      //     // a.download = 'ss.png';
      //     // a.href = canvas.toDataURL('image/png');
      //     // a.click();
      //     this.screenShot = canvas.toDataURL('image/png');
      //   });
      // }, 2000);
    } else {
      setTimeout(() => {
        that.stopStreaming();
        that.stopCalibration();
      }, 250);
      setTimeout(() => {
        that.startCalibration();
      }, 750);
    }
  },
  data() {
    return {
      boundaryPoint: [],
      konvaStage: null,
      textIndex: -1,
      text: '',
      showKonva: true,
      fontSize: 0,
      pageText: '',
      pageQuestions: [],
      error: '',
      screenShot: '',
      isFirstPoint: true,
      isCollecting: false,
      nextPointX: 0,
      nextPointY: 0,
      boundaryStep: 0,
      gazeCount: 0,
      isRecording: false,
    };
  },
  watch: {
    text(newVal) {
      if (newVal !== '') {
        document.querySelector('#readingText').innerHTML = getTextByPassage('textWord', newVal);
        this.$nextTick(() => {
          startSignal(`passageText${this.textIndex + 1}`);
          this.setLineHeight();
          this.getWordData();
        });
      }
    },
    textIndex() {
      this.showKonva = true;
      this.text = this.pageText;
    },
  },
};
</script>

<style scoped>
  #container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0%;
    left: 0%;
  }

  ::v-deep dot-element {
    --color: black;
    position: absolute;
    z-index: 999999;
  }
</style>
