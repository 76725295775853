<template>
  <v-container class="d-flex flex-column align-center pt-15 pb-10" fill-height>
    <v-card class="d-flex flex-column pa-15" elevation="0" height="100%" width="100%">
      <div class="text-h3 bold mb-8">학년을 입력해 주세요</div>
      <v-container fill-height fluid>
        <v-row align="center"
               justify="center">
          <v-col>
            <v-select
              class="mb-15"
              v-model="grade"
              :items="grades"
              label="학년 타입"
              required
              :rules="[(v) => !!v || '학년을 선택해 주세요']"
            ></v-select>
            <v-select
              class="mb-15"
              v-model="gradeType"
              :items="selectedGradeType"
              v-if="showGradeTypes"
              label="학년"
              required
              :rules="[(v) => !!v || '학년을 선택해 주세요']"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <!-- <v-text-field
        class="mb-15"
        v-model="grade"
        label="학년"
        type="number"
        full-width
        required
        :rules="ageRules"
      ></v-text-field> -->

      <v-spacer></v-spacer>
      <v-btn :disabled="isGradeNull" @click="directToHome()" class="" color="primary"
             height="5vh">다음 </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';

axios.defaults.withCredentials = true;

export default {
  name: 'RegisterGrade',
  mounted() {
    this.sampleMethod();
  },
  beforeDestroy() {
    this.sampleMethod();
  },
  data() {
    return {
      sample: 1,
      grade: null,
      gradeRules: [
        (v) => !!v || '학년을 입력해 주세요',
        (v) => (v && v.length <= 2) || '학년을 정확하게 입력해 주세요',
      ],
      gradeType: null,
      grades: ['초등학생', '중학생', '고등학생', '성인'],
      elementryTypes: ['1학년', '2학년', '3학년', '4학년', '5학년', '6학년'],
      middleTypes: ['1학년', '2학년', '3학년'],
      highTypes: ['1학년', '2학년', '3학년'],
    };
  },
  methods: {
    sampleMethod() {
      this.sample += 1;
    },
    async directToHome() {
      this.$store.commit('changeGrade', this.grade);
      this.$store.commit('changeGradeType', this.gradeType === null ? 'adult' : this.gradeType);
      await this.updateUserInfo();
    },
    async updateUserInfo() {
      const userresponse = await axios({
        method: 'POST',
        url: 'http://192.168.0.103:8081/api/auth/updateuserinfo',
        data: {
          userInfo: {
            age: this.$store.state.age,
            gender: this.$store.state.gender,
            grade: this.$store.state.grade,
            gradeType: this.$store.state.gradeType,
          },
        },
      });
      this.$router.push('main');
      return userresponse.status;
    },
  },
  watch: {
    sample(newVal, oldVal) {
      console.log('sample ', oldVal, ' change to ', newVal);
    },
  },
  computed: {
    sampleComputed() {
      return this.sample * 2;
    },
    gradeSelected() {
      return this.grade !== '';
    },
    showGradeTypes() {
      if (this.grade === null || this.grade === '성인') return false;
      return true;
    },
    selectedGradeType() {
      if (this.grade === '초등학생') {
        return this.elementryTypes;
      }
      if (this.grade === '중학생') {
        return this.middleTypes;
      }
      if (this.grade === '고등학생') {
        return this.highTypes;
      }
      return [];
    },
    isGradeNull() {
      if (this.grade === null) return true;
      if (this.grade !== '성인') {
        if (this.gradeType === null) return true;
      }
      return false;
    },
  },
};
</script>
