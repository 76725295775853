<template>
  <v-container>
    <v-btn @click="addPower">power</v-btn>
    <p ref="para" word-break="keep-all"></p>
  </v-container>
</template>

<script>
// eslint-disable-next-line operator-linebreak
const tmpText =
  'ABCD EFG HIJKL MOPQ ABCD EFG HIJKosdufiosdbfovbsdL MOPQ ABCD EFG HIJKL MOPQ ABCD EFG HIJKL MOPQ ABCD EFG HIJKL MOPQ ABCD EFG HIJKL MOPQ';
const powers = [8, 2, 10, 3];
export default {
  name: 'GradationVisualization',
  data() {
    return {
      text: '',
    };
  },
  mounted() {
    this.splitToSpan();
  },
  methods: {
    splitToSpan() {
      const arrTexts = tmpText.split(' ');
      let paragraph = '';
      arrTexts.forEach((item, index) => {
        paragraph += `<span id='paragraph${index}'>${item}</span>`;
        paragraph += ' ';
      });
      //   this.text = paragraph;
      this.$refs.para.innerHTML = paragraph;
    },
    addPower() {
      const arrTexts = tmpText.split(' ');
      for (let i = 0; i < arrTexts.length; i += 1) {
        document.querySelector(`#paragraph${i}`).style.background = `rgba(255,0,0,${
          0.1 * powers[i]
        })`;
      }
    },
  },
};
</script>
