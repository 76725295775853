<template fill-height align-center justify-center>
<v-card class="pt-15 pb-15 d-flex flex-column pl-8 pr-8" height="100vh">
  <v-form
  ref="form"
  v-model="valid"
  lazy-validation
  fill-height
  >

  <v-text-field
  class="mb-15"
  v-model="age"
  label="Age"
  type="number"
  required
  :rules="ageRules"
  ></v-text-field>

  <v-select class="mb-15" v-model="grade" :items="grades" label="Grade" required
  :rules="[v => !!v || '학년을 선택해 주세요']"></v-select>

  <v-select class="mb-15" v-model="gender" :items="genders" label="Gender" required
  :rules="[v => !!v || '성별을 선택해 주세요']"></v-select>

  </v-form>
  <v-spacer></v-spacer>
  <v-btn @click="directHome()">NEXT</v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'RegisterView',
  mounted() {
    this.sampleMethod();
  },
  beforeDestroy() {
    this.sampleMethod();
  },
  data() {
    return {
      sample: 1,
      valid: true,
      age: '',
      grade: '',
      gender: null,
      grades: [
        '1학년',
        '2학년',
        '3학년',
        '4학년',
        '5학년',
        '6학년',
        '7학년',
        '8학년',
        '9학년',
        '10학년',
        '11학년',
        '12학년',
        '13학년',
      ],
      genders: [
        '남성',
        '여성',
      ],
      ageRules: [
        (v) => !!v || '나이를 입력해 주세요',
        (v) => (v && v.length <= 2) || '나이를 정확하게 입력해 주세요',
      ],
    };
  },
  methods: {
    sampleMethod() {
      this.sample += 1;
    },
    directHome() {
      this.$router.push('home');
    },
  },
  watch: {
    sample(newVal, oldVal) {
      console.log('sample ', oldVal, ' change to ', newVal, ' computed ', this.sampleComputed);
    },
  },
  computed: {
    sampleComputed() {
      return this.sample * 2;
    },
  },
};
</script>

<style scoped>

</style>
