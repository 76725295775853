<template>
  <v-container class="d-flex flex-column" fluid fill-height>

    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          결과 데이터를 정말 지우시겠습니까?
        </v-card-title>

        <v-card-text>
          지문 제목: {{itemToBeRemoved.title }}<br>
          이름: {{itemToBeRemoved.name }}<br>
          나이: {{itemToBeRemoved.age }}<br>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            취소
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="removeResult"
          >
            지우기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card width="100%" fluid class="pb-10" elevation="0">
      <v-row>
        <v-col cols="4"> </v-col>
        <v-col cols="2"> </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            single-line
            append-icon="mdi-magnify"
            :label="$t('labelText.search')"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="testList"
        :search="search"
        :loading="isLoading"
        :items-per-page="20"
      >
        <template v-slot:[`item.title`]="{ item }">
          <span :style="{color: item.type === 'notvalid' ||  item.type === 'undefined' ? 'red' : 'black'}">
             {{item.title }}
          </span>
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.actions="{ item }">
          <v-icon @click="directToDetailResult(item)" >mdi-play-circle</v-icon>
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.removes="{ item }">
          <v-icon @click="showRemoveDialog(item)" >mdi-trash-can</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { hostUrl } from '../utils/ConnectionUtils';

const axios = require('axios');

export default {
  name: 'TestListView',
  async mounted() {
    await this.fetchTests();
    console.log('cookie');
  },
  data() {
    return {
      dialog: false,
      itemToBeRemoved: {},
      isLoading: true,
      sample: 1,
      date: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ],
      testList: [],
      search: '',
      headers: [
        {
          text: this.$t('labelText.date'),
          value: 'createdAt',
          align: 'center',
        },
        {
          text: this.$t('labelText.name'),
          value: 'name',
          align: 'center',
        },
        // {
        //   text: '성별',
        //   value: 'gender',
        //   align: 'center',
        // },
        {
          text: this.$t('labelText.age'),
          value: 'age',
          align: 'center',
        },
        {
          text: this.$t('labelText.textTitle'),
          value: 'title',
          align: 'center',
        },
        {
          text: this.$t('labelText.textLevel'),
          value: 'level',
          align: 'center',
        },
        {
          text: this.$t('labelText.showResult'),
          value: 'actions',
          sortable: false,
        },
        {
          text: this.$t('labelText.remove'),
          value: 'removes',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async fetchTests() {
      try {
        this.isLoading = true;
        const response = await axios.get(`${hostUrl}/api/test`);
        if (response.status === 200) {
          console.log(response.data);
          const validTestList = [];

          response.data.tests.forEach((test) => {
            // console.log(test.result);
            if (!test.result) {
              const typedTest = JSON.parse(JSON.stringify(test));
              typedTest.type = 'undefined';
              validTestList.push(typedTest);
              // console.log('undefined test');
            } else if (test.result.fixationMeanDuration === 0 || test.result.saccadicLength === -1) {
              // console.log('not valid test');
              const typedTest = JSON.parse(JSON.stringify(test));
              typedTest.type = 'notvalid';
              validTestList.push(typedTest);
            } else {
              validTestList.push(test);
            }
          });

          this.testList = validTestList;

          this.testList.forEach((test) => {
            // eslint-disable-next-line no-param-reassign
            test.createdAt = this.timeConverter(Date.parse(test.createdAt));
          });
        }
        this.testList.reverse();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    directToDetailResult(item) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push(`newtestDetailResult?_id=${item._id}`);
    },
    showRemoveDialog(item) {
      this.dialog = true;
      this.itemToBeRemoved = item;
    },
    async removeResult() {
      // eslint-disable-next-line no-underscore-dangle
      console.log(`${hostUrl}/api/test/${this.itemToBeRemoved._id}`);
      await axios.delete(
        // eslint-disable-next-line no-underscore-dangle
        `${hostUrl}/api/test/${this.itemToBeRemoved._id}`,
      );

      let index = 0;
      this.testList.forEach((item) => {
        // eslint-disable-next-line no-underscore-dangle
        if (item._id === this.itemToBeRemoved._id) {
          this.testList.splice(index, 1);
        }
        index += 1;
      });
      // this.text = response.data.passage.text.replaceAll(/&nbsp;/g, '');
      //
      this.dialog = false;
    },
    timeConverter(timestamp) {
      const a = new Date(timestamp);
      // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const year = a.getFullYear();
      // const month = months[a.getMonth()];
      let month = a.getMonth() + 1;
      let date = a.getDate();
      let hour = a.getHours();
      let min = a.getMinutes();

      if (month < 10) {
        month = `0${month}`;
      }

      if (date < 10) {
        date = `0${date}`;
      }
      if (min < 10) {
        min = `0${min}`;
      }

      if (hour < 10) {
        hour = `0${hour}`;
      }
      // const sec = a.getSeconds();
      // let time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
      const time = `${year}.${month}.${date} ${hour}:${min}`;
      return time;
    },
  },
};
</script>
