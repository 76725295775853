<template>
  <v-container class="d-flex flex-column align-center justify-center" fill-height v-if="checkUserExist">
    <v-img
      class="mb-15"
      src="https://img.freepik.com/free-vector/world-book-day-illustration-with-book_23-2148868469.jpg?t=st=1654677656~exp=1654678256~hmac=671e120a40182858959ba54c064c10ee1bb491f79bed2e07120b4557b7047587&w=1380"
      max-height="80vh"
      v-if="false"
    ></v-img>

    <v-btn class="align-self-center" text @click="requestKakaoLogin()" v-if="false">
      <v-img src="../assets/kakao_login_medium_narrow.png"></v-img>
    </v-btn>
    <v-card class="pa-8">
      <div v-if="isEn !== undefined">
        <v-card-title>Reading Able</v-card-title>
        <v-text-field required v-model="userId" filled dense rounded class="mt-5 mb-5" label="ID"></v-text-field>
        <v-text-field required v-model="userPwd" filled dense rounded :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text': 'password'" label="Password" @click:append='showPassword=!showPassword'></v-text-field>
        <v-btn class="mt-5" @click="requestUserLogin" block color='primary'>Login</v-btn>
      </div>
      <div v-if="isEn === undefined">
        <v-card-title>리딩에이블</v-card-title>
        <v-text-field required v-model="userId" filled dense rounded class="mt-5 mb-5" label="ID"></v-text-field>
        <v-text-field required v-model="userPwd" filled dense rounded :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text': 'password'" label="Password" @click:append='showPassword=!showPassword'></v-text-field>
        <v-btn class="mt-5" @click="requestUserLogin" block color='primary'>Login</v-btn>
      </div>
    </v-card>
<!--    <v-btn class="mt-5 mb-10" @click="requestAdminLogin"  color='primary'>관리자 로그인</v-btn>-->
    <v-alert class="mt-3" type="error" elevation="2" text v-model="showAlert" dismissible>
      {{ alertMessage }}
    </v-alert>
  </v-container>
</template>

<script>
import axios from 'axios';
import { hostUrl } from '../utils/ConnectionUtils';

axios.defaults.withCredentials = true;

const CODE_SUCCESS = 100;
const CODE_USERINFO_EMPTY = 101;
const CODE_LOGININFO_EMPTY = 102;

export default {
  name: 'LoginView',
  beforeMount() {
    // this.isUserExist();
  },
  mounted() {
    this.isEn = this.$route.query.en;
    this.sampleMethod();
    if (window.localStorage.getItem('id')) {
      this.userId = window.localStorage.getItem('id');
    }
  },
  beforeDestroy() {
    this.sampleMethod();
  },
  data() {
    return {
      isEn: null,
      sample: 1,
      termCheck: false,
      checkUserExist: true,
      showAlert: false,
      alertMessage: '',
      showPassword: false,
      userId: '',
      userPwd: '',
    };
  },
  methods: {
    sampleMethod() {
      this.sample += 1;
    },
    directTerm() {
      this.$router.push('/term');
    },
    async isUserExist() {
      const response = await axios({
        method: 'GET',
        url: `${hostUrl}/api/users/me`,
      });

      if (response.status === 200) {
        if (response.data.code === CODE_SUCCESS) {
          this.$router.push('main');
        } else if (response.data.code === CODE_USERINFO_EMPTY) {
          this.$router.push('term');
        } else if (response.data.code === CODE_LOGININFO_EMPTY) {
          // 처음 로그인 (로그인 정보 없음)
        }
      } else {
        // 통신 오류
        this.showAlert = true;
        this.alertMessage = `code:${response.status} message:${
          response.message ? response.message : ''
        }`;
      }
      this.checkUserExist = true;
    },
    async requestKakaoLogin() {
      const loginResponse = await axios({
        method: 'GET',
        url: 'http://192.168.0.103:8081/api/auth/kakaoreq',
      });
      console.log(`CODE_LOGININFO_EMPTY response: ${JSON.stringify(loginResponse.data)}`);
      window.location.href = loginResponse.data.url;
    },
    checkUserInfoEmpty() {
      if (this.userId === '' || this.userPwd === '') {
        this.showAlert = true;
        this.alertMessage = 'Fill ID and Password';
        return false;
      }
      return true;
    },
    async requestUserLogin() {
      if (!this.checkUserInfoEmpty()) return;
      // eslint-disable-next-line no-unused-expressions
      if (this.userId === 'hawaii' || this.userId === 'hawaii ' || this.userId === 'Hawaii' || this.userId === 'Hawaii ' || this.userId === 'HWAII' || this.userId === 'HWAII ') {
        this.$i18n.locale = 'en';
        this.$store.state.localEN = true;
        this.$store.state.locale = 'en';
        if (window.Unity) {
          window.Unity.call('SetLanguage_en');
        }
      }
      axios.post(`${hostUrl}/api/auth/signin`, {
        email: this.userId.replace(' ', ''),
        password: this.userPwd,
      }, {
        'Content-Type': 'application/json',
        withCredentials: true,
        credentials: 'include',
      }).then((response) => {
        const result = response.data;
        console.log('cookie : ', document.cookie);
        console.log('result.user', result.user);
        if (result.user) {
          window.localStorage.setItem('id', this.userId);
          this.$store.state.currentUser = result.user;
          this.$router.push('main');
          // console.log(document.cookie);
        } else {
          this.showAlert = true;
          this.alertMessage = 'Check ID or Password';
        }
        // eslint-disable-next-line no-unused-vars
      }).catch((e) => {
        this.showAlert = true;
        this.alertMessage = 'Check ID or Password';
      });
    },
    getCookie(cookieName) {
      let cookieValue = null;
      if (document.cookie) {
        const array = document.cookie.split((`${escape(cookieName)}=`));
        if (array.length >= 2) {
          const arraySub = array[1].split(';');
          cookieValue = unescape(arraySub[0]);
        }
      }
      return cookieValue;
    },
    async requestAdminLogin() {
      const response = await axios({
        method: 'POST',
        url: `${hostUrl}/api/auth/signin`,
        data: {
          email: 'dbsdugus00@gmail.com',
          password: '123123',
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }, { withCredentials: true });
      const result = response.data;
      if (result.user) {
        this.$router.push('main');
      } else {
        this.showAlert = true;
        this.alertMessage = 'Login error';
      }
    },
  },
  watch: {
    sample(newVal, oldVal) {
      console.log('sample ', oldVal, ' change to ', newVal, ' computed ', this.sampleComputed);
    },
  },
  computed: {
    sampleComputed() {
      return this.sample * 2;
    },
  },
};
</script>

<style scoped></style>
