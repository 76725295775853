<template>
  <div style="width:100%; text-align: center; align-self: center; align-content: center">
    <div>;

      <v-container fluid>
        <div style="font-size: 2em; margin-top: 0.5em;">Q{{questionIndex+1}}</div>
        <v-row>
          <v-col cols="1">
            <div  v-show="questionIndex>0" style="display:flex; justify-content: center; align-items: center; height:50vh; line-height: 250%; word-break: keep-all">
              <v-img @click="questionIndex-=1" src="../assets/left_arrow.svg"></v-img>
            </div>
          </v-col>
          <v-col cols="10">
            <v-row>
              <v-col>
                <div style="display:flex; justify-content: center; align-items: center; height:50vh; line-height: 250%; word-break: keep-all">
                  <span style="font-size: 1.8em">
                    {{ questionTitle }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <div  v-show="questionIndex < answer.length" style="display:flex; justify-content: center; align-items: center; height:50vh; line-height: 250%; word-break: keep-all">
              <v-img @click="questionIndex+=1" src="../assets/right_arrow.svg"></v-img>
            </div>
          </v-col>
        </v-row>

        <v-row class="mr-1">
              <v-btn class="ma-2" raised plain id="answercorrect" x-large outlined elevation="1" color="primary" block min-height="10vh"  @click='nextQuestion(true)'  style="font-size: 1.4em;">{{$i18n.locale === 'en' ? '○' : '○ 맞습니다'}}</v-btn>
              <v-btn class="ma-2" raised plain id="answerwrong" x-large outlined elevation="1" color="error" block min-height="10vh"  @click='nextQuestion(false)'  style="font-size: 1.4em;" >{{$i18n.locale === 'en' ? '×' : '× 아닙니다'}}</v-btn>
              <v-btn class="ma-2" raised plain id="answeridonknow" x-large outlined elevation="1" color="orange" block min-height="10vh"  @click='nextQuestion("idontknow")'  style="font-size: 1.4em;" >{{$i18n.locale === 'en' ? 'I don\'t know' : '잘 모르겠어요'}}</v-btn>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import { hostUrl } from '../utils/ConnectionUtils';

const axios = require('axios');

export default {
  name: 'TestQuestionView',
  beforeDestroy() {
    if (window.Unity) {
      window.Unity.call('stopRecording');
    }
  },
  mounted() {
    console.log(this.$store.state.question);
    this.question = this.$store.state.question;
    this.questionIndex = 0;
  },
  methods: {
    async nextQuestion(answer) {
      console.log(answer);
      if (answer === 'idontknow') {
        this.iDontKnow[this.questionIndex] = true;
        this.answer[this.questionIndex] = true;
      } else {
        this.answer[this.questionIndex] = answer;
        this.iDontKnow[this.questionIndex] = false;
      }
      if (this.questionIndex + 1 === this.question.length) {
        await this.sendAnswer();
        this.directToResultPage();
      } else {
        this.questionIndex += 1;
      }
      console.log(this.questionIndex);
    },
    directToResultPage() {
      this.$router.push(`newtestDetailResult?_id=${this.$store.state.testId}`);
    },
    async sendAnswer() {
      try {
        await axios.post(`${hostUrl}/api/test/${this.$store.state.testId}`, { answer: this.answer, iDontKnow: this.iDontKnow });
        // console.log(res);
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    setButtonBackgroundColor() {
      document.getElementById('answercorrect').style.backgroundColor = '#fff';
      document.getElementById('answerwrong').style.backgroundColor = '#fff';
      document.getElementById('answeridonknow').style.backgroundColor = '#fff';

      if (this.iDontKnow[this.questionIndex]) {
        document.getElementById('answeridonknow').style.backgroundColor = 'rgba(251, 192, 147, 0.24)';
      } else if (this.answer[this.questionIndex]) {
        document.getElementById('answercorrect').style.backgroundColor = 'rgba(0, 96, 100, 0.24)';
      } else if (this.answer[this.questionIndex] === false) {
        document.getElementById('answerwrong').style.backgroundColor = 'rgba(213, 0, 0, 0.24)';
      }
    },
  },
  data() {
    return {
      question: [],
      answer: [],
      iDontKnow: [],
      questionIndex: -1,
      questionTitle: '',
    };
  },
  watch: {
    questionIndex(val) {
      this.setButtonBackgroundColor();
      if (val > -1 && val < this.question.length) {
        this.questionTitle = this.question[val].questionText;
      } else {
        this.questionTitle = '1';
      }
    },
  },
};
</script>
