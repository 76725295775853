export function getMaxFontSize() { }
export function getTextByWordAoi(wordIdFormat, wordAoi) {
  let resultHtml = '';
  for (let i = 0; i < wordAoi.length; i += 1) {
    const { word, line, order } = wordAoi[i];
    if (i !== wordAoi.length - 1) {
      resultHtml += '<br>';
    }
    resultHtml += `<span id='${wordIdFormat}${line}_${order}'>${word}</span><span> </span> `;
  }
  return resultHtml;
}
export function getTextByWordAoiId(wordIdFormat, wordAoi, id) {
  let resultHtml = '';
  for (let i = 0; i < wordAoi.length; i += 1) {
    const { word, line, order } = wordAoi[i];
    if (i !== wordAoi.length - 1) {
      resultHtml += '<br>';
    }
    resultHtml += `<span id='${wordIdFormat}${line}_${order}_${id}'>${word}</span><span> </span> `;
  }
  return resultHtml;
}
export function getTextByPassage(wordIdFormat, passage) {
  const arrPassage = passage.split(' ');
  let resultHtml = '';
  for (let i = 0; i < arrPassage.length; i += 1) {
    resultHtml += `<span id='${wordIdFormat}${i}' >${arrPassage[i]} </span>`;
  }
  return resultHtml;
}
