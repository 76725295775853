<template>
  <v-container class="d-flex flex-column" fluid fill-height>
    <v-dialog v-model="showDialog" max-width="300">
      <TestStartInfoInputDialog
        @cancelDialog="hideAnnounceDialog"
        @confirmDialog="startTest"
        v-if="showDialog"
      >
      </TestStartInfoInputDialog>
    </v-dialog>
    <v-card width="100%" class="mb-10">
      <v-card-title>
        <v-row
          align="center"
        >
          <v-col cols="6">
            <v-select
              v-model="selectedLevel"
              :items="levelItems"
              item-text="level"
              item-value="value"
              :label="$t('labelText.selectLevel')"
            >
            </v-select>
          </v-col>
          <v-col cols="6" align="right">
            <v-btn outlined @click="exitApp">
              {{$t('buttonText.exit')}}
              <v-icon class="pl-2"> mdi-exit-to-app </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="selectedTextList"
        hide-default-footer
        :search="search"
        mobile-breakpoint="0"
        :loading="isLoading"
        disable-pagination
      >
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.actions="{ item }">
          <v-icon @click="showAnnounceDialog(item)" color="primary">mdi-play-circle</v-icon>
<!--          <v-icon @click="showAnnounceDialog(item)">mdi-play-circle</v-icon>-->
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { hostUrl } from '../utils/ConnectionUtils';
import TestStartInfoInputDialog from '../components/TestStartInfoInputDialog.vue';

const axios = require('axios');

export default {
  name: 'TestStartView',
  async mounted() {
    this.setUnityListener();
    this.getAppSettings();
    await this.fetchPassages();
  },
  data() {
    return {
      isLoading: true,
      search: '',
      sample: 1,
      showDialog: false,
      textList: [],
      selectedTextList: [],
      selectedTest: {},
      headers: [
        {
          text: this.$t('labelText.level'),
          value: 'level',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labelText.textTitle'),
          value: 'title',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labelText.startTest'),
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
      selectedLevel: '',
    };
  },
  methods: {
    exitApp() {
      if (window.Unity) {
        window.Unity.call('exitApp');
      }
    },
    sampleMethod() {
      this.sample += 1;
    },
    directToReadingTextView({
      name, age, gender, type,
    }) {
      this.sendTestStart({
        name, age, gender, type,
      });
    },
    showAnnounceDialog(item) {
      this.selectedTest = item;
      this.showDialog = true;
    },
    hideAnnounceDialog() {
      this.showDialog = false;
    },
    startTest({
      name, age, gender, type,
    }) {
      // eslint-disable-next-line no-underscore-dangle
      this.$store.commit('changePassageId', this.selectedTest._id);
      this.hideAnnounceDialog();
      this.directToReadingTextView({
        name, age, gender, type,
      });
    },
    async sendTestStart({
      name, age, gender, type,
    }) {
      try {
        const response = await axios.post(
          `${hostUrl}/api/test`,
          // 'http://localhost:8081/api/test',
          {
            name, age, gender, level: this.selectedTest.level, title: this.selectedTest.title, type,
          },
          {
            'Content-Type': 'application/json',
          },
        );
        if (response.status === 200) {
          if (window.Unity) {
            // eslint-disable-next-line no-underscore-dangle
            window.Unity.call(`setVariable_testId_${response.data.test._id}`);
          }
          // eslint-disable-next-line no-underscore-dangle
          this.$store.commit('changeTestId', response.data.test._id);
          this.$router.push('testGuidanceView');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPassages() {
      try {
        this.isLoading = true;
        const response = await axios.get(
          `${hostUrl}/api/passage`,
        );
        if (response.status === 200) {
          this.textList = this.sortTextList(response.data.passages);
          this.selectedTextList = this.textList;
        }
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
    sortTextList(list) {
      return list.sort((a1, a2) => a1.level - a2.level);
    },
    getAppSettings() {
      // console.log('getAppSettings');
      if (window.Unity) {
        window.Unity.call('GetAppSettings');
      }
    },
    setUnityListener() {
      console.log('setUnityListener');
      const vue = this;
      window.onAppSettings = (locale, calibrationMode, calibrationPoint) => {
        vue.$i18n.locale = locale;
        vue.$store.commit('changeLocale', locale);
        vue.$store.commit('changeCalibrationMode', calibrationMode);
        vue.$store.commit('changeCalibrationPoint', calibrationPoint);
      };
    },
  },
  computed: {
    levelItems() {
      const items = [
        {
          level: this.$t('labelText.entireLevel'),
          value: 0,
        },
      ];
      for (let i = 0; i < this.textList.length; i += 1) {
        items.push({
          level: this.$t('labelText.levelLabel', { level: this.textList[i].level }),
          value: this.textList[i].level,
        });
      }
      return items;
    },
  },
  watch: {
    selectedLevel(currentLevel) {
      this.selectedTextList = [];
      this.textList.forEach((item) => {
        if (currentLevel === 0) {
          this.selectedTextList.push(item);
        } else if (currentLevel === item.level) {
          this.selectedTextList.push(item);
        }
      });
    },
  },
  components: {
    TestStartInfoInputDialog,
  },
};
</script>
